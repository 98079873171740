import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import CategoryEditView from 'views/Category/Edit';
import { useFetch } from 'hooks';
import CategoryService from 'api/Category';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';

const CategoryEdit = ( {
  entityId, modalOpened, onReload, onCloseModal, toggleLoading, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CategoryService.getCategory( entityId ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      imageFile: dataToFormat.imageUri,
    } ),
    toggleErrorAlert,
    conditional: !!entityId && modalOpened,
    deps: [entityId, modalOpened],
    reInit: true,
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    let dataToSend = formatDataToSend( formData, form );
    dataToSend = {
      ...dataToSend,
      active: dataToSend.active ? '1' : '0',
      imageFile: undefined,
    };
    const [errors, response, responseData] = await handlePromise( entityId
      ? CategoryService.updateCategory( entityId, dataToSend )
      : CategoryService.saveCategory( dataToSend ) );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( formData.imageFile && formData.imageFile.file ) {
      const [imageErrors, imageResponse] = await handlePromise(
        CategoryService.updateCategoryImage( responseData.id, {
          imageFile: formData.imageFile.file,
        } ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }
    toggleLoading( false );
    onReload();
  }, [entityId, toggleLoading, onReload] );

  return (
    <CategoryEditView
      entityId={entityId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
} );

export default connect( null, mapDispatchToProps )( CategoryEdit );
