import Dashboard from 'containers/Dashboard';
import Consultant from 'containers/Consultant';
import Category from 'containers/Category';
import Slide from 'containers/Slide';
import GeneralSettings from 'containers/GeneralSettings';
import ExpressQuestions from 'containers/ExpressQuestions';
import Bonus from 'containers/Bonus';
import Payments from 'containers/Payment';
import Customer from 'containers/Customer';
import Help from 'containers/Help';
import Credit from 'containers/Credit';

export default [
  {
    name: 'dashboard',
    component: Dashboard,
    path: '/',
    icon: 'fas fa-tachometer-alt',
    exact: true,
  },
  {
    name: 'configurations',
    icon: 'fas fa-cogs',
    views: [
      {
        name: 'categories',
        component: Category,
        path: '/categories',
        icon: 'fas fa-certificate',
      },
      {
        name: 'slides',
        component: Slide,
        path: '/slides',
        icon: 'fas fa-images',
      },
      {
        name: 'credit',
        component: Credit,
        path: '/credit',
        icon: 'fas fa-images',
      },
      {
        name: 'settings',
        component: GeneralSettings,
        path: '/settings',
        icon: 'fas fa-cog',
      },
      {
        name: 'help',
        component: Help,
        path: '/help',
        icon: 'fas fa-question-circle',
      },
    ],
  },
  {
    name: 'consultants',
    component: Consultant,
    path: '/consultants',
    icon: 'fas fa-user-tag',
  },
  {
    name: 'customers',
    component: Customer,
    path: '/customers',
    icon: 'fas fa-user-friends',
  },
  {
    name: 'bonus',
    component: Bonus,
    path: '/bonus',
    icon: 'fas fa-gift',
  },
  {
    name: 'paymentRequests',
    component: Payments,
    path: '/payments',
    icon: 'fas fa-tasks',
  },
  {
    name: 'expressQuestions',
    component: ExpressQuestions,
    path: '/express-questions',
    icon: 'fas fa-hourglass-end',
  },
  /* {
    name: 'settings',
    path: '/settings',
    icon: 'fas fa-cog',
    views: [
      {
        name: 'chat',
        component: Dashboard,
        path: '/params/chat',
        icon: 'fas fa-comment-dots',
      },
      {
        name: 'offlineMessages',
        component: Dashboard,
        path: '/params/offline-messages',
        icon: 'fas fa-comment-slash',
      },
      {
        name: 'balance',
        component: Dashboard,
        path: '/params/balance',
        icon: 'fas fa-hand-holding-usd',
      },
    ],
  }, */
];
