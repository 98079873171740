import React from 'react';
import { Field } from 'react-final-form';

import DropzoneInput from '../utils/DropzoneInput';

const DropzoneField = React.memo( ( {
  field, validate, value, name, ...rest
} ) => {
  if ( field ) {
    return (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError );

          return (
            <DropzoneInput
              {...input}
              {...rest}
              value={input.value}
              inputOnChange={input.onChange}
              inputName={input.name}
              focused={meta.active}
              meta={meta}
              isInvalid={isInvalid}
            />
          );
        }}
      </Field>
    );
  }
  return (
    <DropzoneInput
      {...rest}
      value={value}
      inputName={name}
    />
  );
} );

export default DropzoneField;
