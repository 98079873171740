import React from 'react';
import {
  Button, Container, Row, Col,
} from 'reactstrap';
import moment from 'moment';

import {
  AsyncTable, AddBtn, SimpleHeader, TableEditBtn, Intl, TableGenericFilter,
} from 'components';

export default ( {
  data, isLoading, onOpenEditModal, onFetchData = () => {}, goToDetail,
} ) => (
  <>
    <SimpleHeader title="consultants" />
    <Container fluid>
      <AsyncTable
        data={data.data}
        total={data.total}
        isLoading={isLoading}
        rightContent={<AddBtn title="newConsultant" onClick={onOpenEditModal} />}
        onFetchData={onFetchData}
        Filters={( { filters, setFilter } ) => (
          <Row>
            <Col md={5}>
              <TableGenericFilter
                filters={filters}
                setFilter={setFilter}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: '',
            accessor: 'imageUri',
            customWidth: 80,
            disableSorting: true,
            Cell: ( { cell } ) => (
              cell.value ? <img src={cell.value} className="avatar rounded-circle" alt="" />
                : (
                  <div className="avatar rounded-circle">
                    <i className="fa fa-user text-dark" />
                  </div>
                )
            ),
          },
          {
            Header: 'email',
            accessor: 'email',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value}
                onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
              />
            ),
          },
          {
            Header: 'name',
            accessor: 'name',
          },
          {
            Header: 'rating',
            accessor: 'rating',
          },
          {
            Header: 'balance',
            accessor: 'totalAmount',
          },
          {
            Header: 'lastConnection',
            accessor: 'lastLogin',
            Cell: ( { cell } ) => ( cell.value ? moment( cell.value ).format( 'DD/MM/YY HH:mm' ) : null ),
          },
          {
            Header: 'status',
            accessor: 'status',
            Cell: ( { cell } ) => ( cell.value ? <Intl id={cell.value} /> : null ),
          },
          {
            accessor: 'id',
            disableSorting: true,
            customWidth: 100,
            Header: '',
            Cell: ( { cell } ) => (
              <Button
                color={cell.row.original.enabled ? 'link-danger' : 'link'}
                outline
                size="app-sm"
                onClick={() => goToDetail( cell.value )}
              >
                <Intl id="detail" />
              </Button>
            ),
          },
          /* {
            accessor: 'id',
            disableSorting: true,
            customWidth: 100,
            Header: '',
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          }, */
        ]}
      />
    </Container>
  </>
);
