import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import GeneralSettingsView from 'views/GeneralSettings';
import SettingsService from 'api/Settings';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import { useFetch } from 'hooks';

const GeneralSettings = ( { toggleLoading, toggleErrorAlert } ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: SettingsService.getSetting,
    toggleErrorAlert,
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      consultantTop1: dataToFormat.consultantTop1 ? _.map( dataToFormat.consultantTop1,
        ( item ) => ( { value: item.id, label: item.name } ) ) : [],
      consultantTop2: dataToFormat.consultantTop2 ? _.map( dataToFormat.consultantTop2,
        ( item ) => ( { value: item.id, label: item.name } ) ) : [],
    } ),
  } );

  const submitForm = useCallback( async ( formData ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      SettingsService.updateSettings( {
        version: formData.version,
        minPaymentAmount: formData.minPaymentAmount,
        minDaysPaymentRequest: formData.minDaysPaymentRequest,
        urlConsultantRegister: formData.urlConsultantRegister,
        expressCost: formData.expressCost,
        consultantTop1: formData.consultantTop1
          ? _.map( formData.consultantTop1, ( item ) => ( item.value ? item.value : item ) ) : [],
        consultantTop2: formData.consultantTop2
          ? _.map( formData.consultantTop2, ( item ) => ( item.value ? item.value : item ) ) : [],
        chatConfig: JSON.stringify( formData.chatConfig ),
        rates: JSON.stringify( formData.rates ),
      } ),
    );
    toggleLoading( false );
    if ( !response.ok ) return errors;
  }, [toggleLoading] );

  return (
    <GeneralSettingsView
      data={data}
      isLoading={dataLoading}
      submitForm={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( GeneralSettings );
