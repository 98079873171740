import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import SlideEditView from 'views/Slide/Edit';
import { useFetch } from 'hooks';
import SlideService from 'api/Slide';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';

const SlideEdit = ( {
  entityId, modalOpened, onReload, onCloseModal,
  toggleLoading, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => SlideService.getSlide( entityId ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      imageFile: dataToFormat.imageUri,
    } ),
    toggleErrorAlert,
    conditional: !!entityId && modalOpened,
    deps: [entityId, modalOpened],
    reInit: true,
  } );

  const [isInternal, setInternal] = useState( false );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    let dataToSend = formatDataToSend( formData, form );
    dataToSend = {
      ...dataToSend,
      active: dataToSend.active ? '1' : '0',
      internalUrl: dataToSend.internalUrl ? 'true' : 'false',
      imageFile: undefined,
    };
    const [errors, response, responseData] = await handlePromise( entityId
      ? SlideService.updateSlide( entityId, dataToSend )
      : SlideService.saveSlide( dataToSend ) );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( formData.imageFile && formData.imageFile.file ) {
      const [imageErrors, imageResponse] = await handlePromise(
        SlideService.updateSlideImage( responseData.id, {
          imageFile: formData.imageFile.file,
        } ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }
    toggleLoading( false );
    onReload();
  }, [entityId, toggleLoading, onReload] );

  useEffect( ( ) => {
    if ( data?.internalUrl !== undefined ) {
      setInternal( data?.internalUrl );
    }
  }, [data] );

  return (
    <SlideEditView
      entityId={entityId}
      setInternal={setInternal}
      isInternal={isInternal}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( SlideEdit );
