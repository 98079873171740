import React from 'react';

import { TextField, ModalForm } from 'components';
import { required, composeValidators, lessThanOrEqualChar } from 'config/InputErrors';

export default React.memo( ( {
  data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title="createPayment"
    showErrorsInFooter
    isLoading={isLoading}
    size="md"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <>
      <TextField
        field="amount"
        label="amount"
        type="number"
        validate={required}
      />
      <TextField
        field="description"
        label="description"
        type="textarea"
        rows={5}
        validate={composeValidators( required, lessThanOrEqualChar( 150 ) )}
      />
    </>
  </ModalForm>
) );
