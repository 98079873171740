import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';

import { ExpressQuestionsMessage, Intl } from 'components';

export default ( {
  messages, hasMoreMessages, loadNextPageMessages,
} ) => {
  const isSameDay = ( currentMessage = {}, diffMessage = {} ) => {
    if ( !diffMessage.createdAt ) return false;

    const currentDate = moment.utc( Number( `${currentMessage.createdAt}000` ) );
    const diffDate = moment.utc( Number( `${diffMessage.createdAt}000` ) );

    if ( !currentDate.isValid() || !diffDate.isValid() ) return false;

    return currentDate.isSame( diffDate, 'day' );
  };

  return (
    <>
      {_.map( messages, ( item, index ) => {
        const previous = messages[index + 1];
        return (
          <div key={item.id}>
            {!isSameDay( item, previous )
            && (
              <div className="row no-gutters chat-messages-message-date">
                <div className="col">
                  <span>{moment.utc( Number( `${item.createdAt}` ) ).local().format( 'MMM DD, YYYY' )}</span>
                </div>
              </div>
            )}
            <ExpressQuestionsMessage message={item} />
          </div>
        );
      } )}
      {!!hasMoreMessages
      && (
        <div className="chat-messages-load">
          <Button color="link" onClick={loadNextPageMessages}>
            <Intl id="loadMore" />
          </Button>
        </div>
      )}
    </>
  );
};
