import React from 'react';
import {
  Button, Col, Container, Row,
} from 'reactstrap';

import {
  AsyncTable, SimpleHeader, ActiveIcon, TableEditBtn, Intl, TableGenericFilter,
} from 'components';
import { formatPhone } from 'utils';
import moment from 'moment';

export default ( {
  data, isLoading, history, onFetchData = () => {}, toggleActive,
} ) => (
  <>
    <SimpleHeader title="customers" />
    <Container fluid>
      <AsyncTable
        data={data.data}
        total={data.total}
        isLoading={isLoading}
        onFetchData={onFetchData}
        Filters={( { filters, setFilter } ) => (
          <Row>
            <Col md={5}>
              <TableGenericFilter
                filters={filters}
                setFilter={setFilter}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: '',
            accessor: 'imageUri',
            customWidth: 80,
            disableSorting: true,
            Cell: ( { cell } ) => (
              cell.value ? <img src={cell.value} className="avatar rounded-circle" alt="" />
                : (
                  <div className="avatar rounded-circle">
                    <i className="fa fa-user text-dark" />
                  </div>
                )
            ),
          },
          {
            Header: 'email',
            accessor: 'email',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value || '-'}
                onClick={() => history.push( `/customers/${cell.row.original.id}` )}
              />
            ),
          },
          {
            Header: 'name',
            accessor: 'name',
          },
          {
            Header: 'phoneNumber',
            accessor: 'phoneNumber',
            Cell: ( { cell } ) => formatPhone(
              cell.row.original.codeCountry
                ? cell.row.original.codeCountry + cell.value : cell.value,
            ),
          },
          {
            Header: 'credit',
            accessor: 'credit',
          },
          {
            Header: 'lastConnection',
            accessor: 'lastLogin',
            Cell: ( { cell } ) => ( cell.value ? moment( cell.value ).format( 'DD/MM/YY HH:mm' ) : null ),
          },
          {
            Header: 'active',
            accessor: 'enabled',
            Cell: ( { cell } ) => <ActiveIcon active={cell.value} />,
          },
          {
            accessor: 'id',
            disableSorting: true,
            customWidth: 100,
            Header: '',
            Cell: ( { cell } ) => (
              <Button
                color={cell.row.original.enabled ? 'link-danger' : 'link'}
                outline
                size="app-sm"
                onClick={() => toggleActive( cell.row.original )}

              >
                <Intl id={cell.row.original.enabled ? 'disable' : 'enable'} />
              </Button>
            ),
          },
        ]}
      />
    </Container>
  </>
);
