import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from 'reactstrap';
import {
  Select, Intl, LocalTable,
} from 'components';


export default ( { filter, data, setFilter } ) => (
  <Container fluid className="mt-4">
    <Row>
      <Col md={{ size: 3, offset: 9 }}>
        <Select
          size="sm"
          options={[{ id: 'today', name: 'today' }, { id: 'lastMonth', name: 'lastMonth' }, { id: 'lastYear', name: 'lastYear' }]}
          translateOptions
          value={filter}
          onChange={setFilter}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <Card className="card-stats">
          <CardHeader>
            <h3 className="mb-0">
              <Intl id="consultants" />
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="active" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.consultant?.active}
                </h2>
              </Col>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="expressQuestions" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.consultant?.express}
                </h2>
              </Col>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="chats" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.consultant?.chats}
                </h2>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card className="card-stats">
          <CardHeader>
            <h3 className="mb-0">
              <Intl id="clients" />
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="active" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.customer?.active}
                </h2>
              </Col>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="expressQuestions" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.customer?.express}
                </h2>
              </Col>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="chats" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.customer?.chats}
                </h2>
              </Col>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="purchasedPackages" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.customer?.packages}
                </h2>
              </Col>
              <Col>
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <Intl id="minutes" />
                </CardTitle>
                <h2 className="mb-0 font-weight-400">
                  {data?.customer?.minutes}
                </h2>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col md>
        <Card>
          <LocalTable
            onlyTable
            columns={[
              { Header: 'consultant', accessor: 'name', disableSorting: true },
              { Header: 'time', accessor: 'time', disableSorting: true },
              { Header: 'chat', accessor: 'chat', disableSorting: true },
            ]}
            data={data?.consultants || []}
          />
        </Card>
      </Col>
      <Col md>
        <Card>
          <LocalTable
            onlyTable
            columns={[
              { Header: 'category', accessor: 'name', disableSorting: true },
              { Header: 'consultantTotal', accessor: 'total', disableSorting: true },
            ]}
            data={data?.categories || []}
          />
        </Card>
      </Col>
    </Row>

    <Card className="card-stats">
      <CardHeader>
        <h3 className="mb-0">
          <Intl id="userConnections" />
        </h3>
      </CardHeader>
      <CardBody>
        <div className="chartdiv" style={{ width: '100%', height: '300px' }} />
      </CardBody>
    </Card>
  </Container>
);
