import React from 'react';

import TextField from '../../TextField';

const TableGenericFilter = React.memo( ( {
  filters, setFilter,
} ) => (
  <TextField
    name="criteria"
    placeholder="typeSearch"
    classNames={{ formGroup: 'm-0' }}
    value={filters.criteria}
    onChange={( value ) => setFilter( 'criteria', value )}
  />
) );

export default TableGenericFilter;
