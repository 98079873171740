import React from 'react';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Row,
  Col,
} from 'reactstrap';

import { Select } from 'components';
import Pagination from '../Pagination';
import TableUI from '../TableUI';

export default React.memo( ( {
  getTableProps, getRowProps, headerGroups, page, pageIndex, prepareRow, gotoPage, canPreviousPage,
  previousPage, canNextPage, nextPage, pageCount, setPageSize, pageSize, filters,
  setFilter, isLoading, customFilters, titleContent, rightContent,
  containerClassName, totalElements, pageOptions,
} ) => (
  <Card
    className={classnames( 'card-app-default', containerClassName, {
      'app-component-loading': isLoading,
    } )}
  >
    <CardHeader className="border-0 py-3 px-2">
      <Row className="align-items-center">
        {( titleContent || customFilters )
          ? (
            <Col xs={12} md={7} lg={8} className="mb-2 mb-md-0">
              {titleContent}
              {customFilters ? customFilters( { filters, setFilter } ) : null}
            </Col>
          ) : null }

        <Col
          xs={12}
          md={( titleContent || customFilters ) ? 5 : 12}
          lg={( titleContent || customFilters ) ? 4 : 12}
        >
          <div className="w-100 d-flex flex-wrap align-items-center justify-content-end">
            <Select
              name="size"
              size="xsm"
              value={pageSize}
              classNames={{ formGroup: 'm-0 pagination-select' }}
              options={pageOptions || [{ id: 10, name: '10 items' }, { id: 20, name: '20 items' }]}
              onChange={( value ) => {
                gotoPage( 0 );
                setPageSize( value );
              }}
            />
            {!!rightContent
            && (
              <div className="ml-0 ml-sm-2 mt-2 mt-md-0 p-0 d-none d-md-block">
                {rightContent}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </CardHeader>

    <CardBody className="py-0 px-2 border-0">
      <TableUI
        page={page}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        getRowProps={getRowProps}
      />
    </CardBody>

    <CardFooter className="py-4 px-2 border-0">
      <Pagination
        totalElements={totalElements}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
      />
    </CardFooter>
  </Card>
) );
