import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';

import CustomerDetailView from 'views/Customer/Detail';
import { useFetch, useFetchTableData } from 'hooks';
import CustomerService from 'api/Customer';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';

const CustomerDetail = ( { match, toggleErrorAlert, toggleLoading } ) => {
  const entityId = useRef( match.params.id );

  const [data, dataLoading, setData] = useFetch( {
    initialState: {},
    promise: () => CustomerService.getCustomer( entityId.current ),
    toggleErrorAlert,
    conditional: !!entityId,
  } );

  const toggleActive = useCallback( async () => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      CustomerService.updateCustomer( entityId.current, {
        enabled: data.enabled ? '0' : '1',
      } ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );

    setData( {
      ...data,
      enabled: !data.enabled,
    } );
  }, [data, setData, toggleErrorAlert, toggleLoading] );

  const [fetchOperations, operations, operationsLoading] = useFetchTableData( {
    promise: ( params ) => CustomerService.getCustomerOperations( entityId.current, params ),
    toggleErrorAlert,
    deps: [],
  } );

  return (
    <CustomerDetailView
      data={data}
      toggleActive={toggleActive}
      isLoading={dataLoading}
      operations={operations}
      operationsLoading={operationsLoading}
      onFetchOperations={fetchOperations}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CustomerDetail );
