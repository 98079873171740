import React from 'react';

import classnames from 'classnames';
import { InputGroupAddon, InputGroupText } from 'reactstrap';

const InputGroupAddonComp = ( {
  icon, iconClass, isInvalid, addonType,
} ) => (
  <InputGroupAddon
    addonType={addonType}
    className={classnames( { 'is-invalid': isInvalid } )}
  >
    <InputGroupText>
      {icon || <i className={iconClass} />}
    </InputGroupText>
  </InputGroupAddon>
);

export default InputGroupAddonComp;
