import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, Dropzone, Checkbox, Select,
} from 'components';
import { composeValidators, required, validUrl } from 'config/InputErrors';

export default ( ( {
  entityId, data, isLoading, open, onClose, onSubmit, isInternal, setInternal,
} ) => (
  <ModalForm
    initialValues={data}
    title={entityId ? 'editSlide' : 'newSlide'}
    showErrorsInFooter
    isLoading={isLoading}
    size="md"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <Row>
      <Col md={4}>
        <Dropzone
          field="imageFile"
          label="image"
          validate={required}
        />
      </Col>
      <Col>
        <TextField field="name" label="name" validate={required} />
        {!isInternal && (
          <TextField field="link" label="link" validate={composeValidators( required, validUrl )} />
        )}
        {isInternal && (
          <Select
            field="link"
            label="link"
            translateOptions
            options={[
              { id: 'http://Invitations', name: 'Invitations' },
              { id: 'http://Bonus', name: 'Bonus' },
              { id: 'http://Buy', name: 'Buy' },
              { id: 'http://ConsultantList', name: 'ConsultantList' },
              { id: 'http://Balance', name: 'Balance' },
              { id: 'http://Messages', name: 'Messages' },
            ]}
            validate={required}
          />
        )}
        <Checkbox field="internalUrl" label="InternalUrl" horizontal onChange={( val ) => {setInternal( val )}} />
        <Checkbox field="active" label="active" horizontal />
      </Col>
    </Row>
  </ModalForm>
) );
