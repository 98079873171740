import React, { useEffect, useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Spinner } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import moment from 'moment';
import 'moment/locale/es';

import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-pro-react.scss?v1.0.0';

import PrivateLayout from 'layouts/Private';
import AuthLayout from 'layouts/Auth';
import AppLocale from 'i18n';
import { Intl } from 'components';

const PrivateRoute = ( { component: Component, ...rest } ) => (
  <Route
    {...rest}
    render={( props ) => (
      rest.user && rest.user.access_token
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/auth/login' }} />
    )}
  />
);

const PublicRoute = ( { component: Component, ...rest } ) => (
  <Route
    {...rest}
    render={( props ) => (
      !rest.user || !rest.user.access_token
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/' }} />
    )}
  />
);

const App = ( {
  user, isLoading, alert,
} ) => {
  moment.locale( 'es' );

  const messages = AppLocale.es;
  const notificationAlert = useRef( null );
  const isInitialMount = useRef( true );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else if ( alert && alert.msg ) {
      const options = {
        place: 'tr',
        message: (
          <div className="alert-text">
            <span data-notify="message">
              <Intl id={alert.msg} />
            </span>
          </div>
        ),
        type: alert.alertType || 'danger',
        icon: 'ni ni-bell-55',
        autoDismiss: 10,
      };
      if ( alert ) notificationAlert.current.notificationAlert( options );
    }
  }, [alert] );

  return (
    <IntlProvider
      locale="es"
      messages={messages}
    >
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <Switch>
        <PublicRoute path="/auth" component={AuthLayout} user={user} />
        <PrivateRoute path="/" component={PrivateLayout} user={user} />
        <Redirect from="*" to="/" />
      </Switch>

      {isLoading
      && (
        <div className="app-loading">
          <Spinner type="grow" color="primary" style={{ width: '3rem', height: '3rem' }} />
        </div>
      )}
    </IntlProvider>
  );
};

const mapStateToProps = ( { settings, user } ) => {
  const { locale, isLoading, alert } = settings;
  return {
    locale, isLoading, alert, user,
  };
};

export default connect( mapStateToProps )( App );
