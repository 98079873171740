import React from 'react';
import classnames from 'classnames';
import {
  Table,
} from 'reactstrap';

import { Intl } from 'components';

export default React.memo( ( {
  getTableProps, headerGroups, page, prepareRow, getRowProps = () => ( {} ),
} ) => (
  <div className="table-responsive">
    <Table className="align-items-center table-flush" {...getTableProps()}>
      <thead className="thead-light">
        {headerGroups.map( ( headerGroup ) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map( ( column ) => (
              <th
                {...( !column.disableSorting
                  ? column.getHeaderProps( column.getSortByToggleProps() )
                  : column.getHeaderProps( {
                    style: column.customWidth ? {
                      flex: `${column.customWidth} 0 auto`,
                      width: column.customWidth,
                      maxWidth: column.customWidth,
                    } : {},
                  } ) )
              }
              >
                {column.Header ? <Intl id={column.Header} /> : null}
                {!column.disableSorting
              && (
                <span
                  className={classnames( 'fas ml-1', {
                    'fa-sort': !column.isSorted,
                    'fa-sort-up': column.isSorted && !column.isSortedDesc,
                    'fa-sort-down': column.isSorted && column.isSortedDesc,
                  } )}
                />
              )}
              </th>
            ) )}
          </tr>
        ) )}
      </thead>
      <tbody className="list">
        {
        page.length ? page.map(
          ( row ) => prepareRow( row ) || (
            <tr {...row.getRowProps( getRowProps( row ) )}>
              {row.cells.map( ( cell ) => (
                <td
                  {...cell.getCellProps(
                    // eslint-disable-next-line no-nested-ternary
                    cell.column.customWidth ? {
                      style: {
                        verticalAlign: 'middle',
                        flex: `${cell.column.customWidth} 0 auto`,
                        width: cell.column.customWidth,
                        maxWidth: cell.column.customWidth,
                      },
                      ...cell.column.getCellProps ? cell.column.getCellProps( cell ) : {},
                    } : cell.column.getCellProps ? cell.column.getCellProps( cell ) : {},
                  )}
                >
                  {cell.render( 'Cell' )}
                </td>
              ) )}
            </tr>
          ),
        ) : (
          <tr>
            <td colSpan={100} className="text-center">
              <Intl id="noTableData" />
            </td>
          </tr>
        )
      }
      </tbody>
    </Table>
  </div>
) );
