import api from 'config/Api';
import { formatFormDataBody } from 'utils';

const baseEndpointList = 'administration/slides';
const baseEndpoint = 'administration/slide';

export default {

  getSlides( params ) {
    return api.get( baseEndpointList, params );
  },

  getSlide( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveSlide( data ) {
    return api.post( baseEndpoint, data );
  },

  updateSlide( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  updateSlideImage( id, data ) {
    return api.post( `${baseEndpoint}/${id}/image`, formatFormDataBody( data ) );
  },

  deleteSlide( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
