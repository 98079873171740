import React from 'react';

import classnames from 'classnames';
import TextField from '../../TextField';

const TableTextFieldFilter = React.memo( ( {
  name, filters, inline, setFilter, ...rest
} ) => (
  <TextField
    {...rest}
    size="sm"
    name={name}
    formGroupClass={classnames( 'm-0', {
      'd-flex align-items-center': inline,
    } )}
    labelClass={classnames( rest.labelClass, {
      'mr-2': inline,
    } )}
    value={filters[name]}
    onChange={( value ) => setFilter( name, value )}
  />
) );

export default TableTextFieldFilter;
