import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import PaymentView from 'views/Consultant/Payment';
import PaymentService from 'api/Payment';

import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';

const Payment = ( {
  entityId, modalOpened, onReload, onCloseModal, toggleLoading,
} ) => {
  const submitForm = useCallback( async ( formData ) => {
    toggleLoading( true );

    const dataToSend = {
      amount: formData.amount,
      comment: formData.description,
    };
    const [errors, response] = await handlePromise(
      PaymentService.completePaymentRequest( entityId.id, dataToSend ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    toggleLoading( false );
    onReload();
  }, [entityId, toggleLoading, onReload] );

  return (
    <PaymentView
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
} );

export default connect( null, mapDispatchToProps )( Payment );
