import React from 'react';
import { Field } from 'react-final-form';

import WysiwygInput from '../utils/WysiwygInput';

const Wysiwyg = React.memo( ( {
  field, validate, value, name, ...rest
} ) => (
  <Field name={field} validate={validate}>
    {( { meta, input } ) => {
      const isInvalid = ( meta.touched && meta.error )
        || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError );

      return (
        <WysiwygInput
          {...input}
          {...rest}
          value={input.value || ''}
          inputOnChange={input.onChange}
          meta={meta}
          isInvalid={isInvalid}
        />
      );
    }}
  </Field>
) );

export default Wysiwyg;
