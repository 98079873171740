import { useCallback } from 'react';

import { handlePromise, formatDataToSend } from 'utils';

export default ( {
  promise, callback, format, infoMsg, deps = [], toggleLoading, toggleInfoAlert,
} ) => useCallback( async ( formData, form ) => {
  toggleLoading( true );

  let dataToSend = formatDataToSend( formData, form );
  if ( format ) dataToSend = format( dataToSend );
  const [errors, response, responseData] = await handlePromise( promise( dataToSend ) );
  toggleLoading( false );

  if ( !response.ok ) return errors;
  if ( toggleInfoAlert ) toggleInfoAlert( infoMsg || 'dataSaved' );
  if ( callback ) callback( formData, responseData );
}, [...deps] ); //eslint-disable-line
