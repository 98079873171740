import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { FormGroup, Input } from 'reactstrap';

import { Intl } from 'components';

const TextFieldInput = React.memo( ( {
  label, value, inputName, size = 'sm', intl, validateTranslateValues, isInvalid,
  disableOnEnter, allowZero = false, focused, meta, placeholder, translate = true, labelOnFocus,
  inputClassName, classNames = {}, inputOnChange, onChange, maxChars, ...rest
} ) => (
  <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
    {label
    && (
      <label className={classnames( classNames.label, 'form-control-label' )}>
        {translate ? <Intl id={label} /> : label}
      </label>
    )}

    <div className="w-100">
      <Input
        {...rest}
        placeholder={placeholder ? intl.formatMessage( { id: placeholder } ) : null}
        className={classnames( inputClassName, {
          'is-invalid': isInvalid,
          'form-control-lg': size === 'lg',
          'form-control-sm': size === 'sm',
          'form-control-xsm': size === 'xsm',
        } )}
        value={allowZero && value === 0 ? value : value || ''}
        onChange={( e ) => {
          let val = e.target.value;
          if ( rest && rest.type === 'number' ) {
            val = val === '' ? val : Number( val );
          }
          if ( inputOnChange ) inputOnChange( val );
          if ( onChange ) onChange( val );
        }}
        onKeyPress={disableOnEnter ? ( e ) => {
          if ( e.key === 'Enter' ) e.preventDefault();
        } : rest.onKeyPress}
      />

      {labelOnFocus && focused
      && (
        <label className="form-control-label mb-1">
          <Intl id={labelOnFocus} />
        </label>
      )}
      {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={validateTranslateValues} />
        </div>
      )}
      {rest.maxLength
      && (
        <div className="form-control-label mb-2 text-gray">
          {rest.maxLength - value.length}
          {' '}
          <Intl id="remainingCharacters" />
        </div>
      )}
    </div>
  </FormGroup>
) );

export default injectIntl( TextFieldInput );
