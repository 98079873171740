import { useState, useEffect } from 'react';
import _ from 'lodash';

import { handlePromise } from 'utils';

export default ( {
  initialState, promise, format, conditional, reInit, deps = [], toggleErrorAlert,
} ) => {
  const [data, setData] = useState( initialState );
  const [dataLoading, setDataLoading] = useState();

  useEffect( () => {
    const getData = async () => {
      setDataLoading( true );
      const [errors, response, responseData] = await handlePromise( promise() );
      setDataLoading( false );

      if ( !response.ok ) {
        return toggleErrorAlert
          ? toggleErrorAlert( errors ) : [initialState, false];
      }
      if ( format ) setData( format( responseData ) );
      else setData( responseData );
    };
    if ( conditional === undefined ) getData();
    else if ( conditional ) getData();
    else if ( reInit && !_.isEqual( data, initialState ) ) setData( initialState );
  }, [...deps] ); //eslint-disable-line

  return [data, dataLoading, setData];
};
