import api from 'config/Api';
import { formatFormDataBody } from 'utils';

const baseEndpointList = 'administration/consultants';
const baseEndpoint = 'administration/consultant';

export default {

  getConsultants( params ) {
    return api.get( baseEndpointList, params );
  },

  filterConsultantsByQuery( query ) {
    return api.get( baseEndpointList, { 'filters[criteria]': query, limit: 50 } );
  },

  getConsultant( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  getConsultantOperations( id, params ) {
    return api.get( `${baseEndpoint}/${id}/operations`, params );
  },

  saveConsultant( data ) {
    return api.post( baseEndpoint, data );
  },

  saveConsultantPayment( id, data ) {
    return api.post( `${baseEndpoint}/${id}/operation`, data );
  },

  updateConsultant( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  updateConsultantImage( id, data ) {
    return api.post( `${baseEndpoint}/${id}/image`, formatFormDataBody( data ) );
  },

  deleteConsultant( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
