import React, { useState } from 'react';
import { Field } from 'react-final-form';

import DatepickerInput from '../utils/DatepickerInput';

const Datepicker = React.memo( ( {
  field, validate, value, name, ...rest
} ) => {
  const [focused, setFocused] = useState( false );

  if ( field ) {
    return (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError )
            || meta.error === 'INVALID';

          return (
            <DatepickerInput
              {...input}
              {...rest}
              onBlur={() => {
                if ( input.onBlur ) input.onBlur();
                if ( rest.onBlur ) rest.onBlur();
              }}
              value={input.value || ''}
              inputOnChange={input.onChange}
              inputName={input.name}
              focused={meta.active}
              meta={meta}
              isInvalid={isInvalid}
            />
          );
        }}
      </Field>
    );
  }
  return (
    <DatepickerInput
      {...rest}
      value={value || ''}
      inputName={name}
      focused={focused}
      onFocus={() => setFocused( true )}
      onBlur={() => setFocused( false )}
    />
  );
} );

export default Datepicker;
