import React, { useState } from 'react';
import {
  useTable, usePagination, useSortBy, useFilters,
} from 'react-table';
import _ from 'lodash';

import TableUI from '../utils/ContainerTableUI';

export default React.memo( ( {
  data, columns, Filters, total, onFetchData, defaultPageSize = 10, disableSorting = false,
  getRowProps, getCellProps, ...rest
} ) => {
  const [filters, setFilterDefault] = useState( {} );

  const {
    getTableProps, headerGroups, page, prepareRow, gotoPage,
    canPreviousPage, previousPage, canNextPage, nextPage, pageCount, setPageSize,
    state: { pageIndex, sortBy, pageSize },
  } = useTable( {
    data,
    columns: React.useMemo(
      () => columns,
      [columns],
    ),
    disableSorting,
    initialState: { pageSize: defaultPageSize },
    manualFilters: true,
    manualPagination: true,
    manualSortBy: true,
    pageCount: Math.ceil( total / defaultPageSize ),
  }, useFilters, useSortBy, usePagination );

  const setFilter = ( column, value ) => {
    const newFilter = {};
    newFilter[column] = value;
    if ( pageIndex > 0 ) {
      gotoPage( 0 );
    }
    setFilterDefault( { ...filters, ...newFilter } );
  };

  React.useEffect( () => {
    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
    };
    if ( sortBy && sortBy.length ) apiParams[`sortby[${sortBy[0].id}]`] = sortBy[0].desc ? 'DESC' : 'ASC';
    if ( filters ) {
      _.map( filters, ( filter, key ) => {
        if ( filter ) apiParams[`filters[${key}]`] = filter;
      } );
    }
    onFetchData( apiParams );
  }, [onFetchData, pageIndex, pageSize, sortBy, filters] );

  return (
    <TableUI
      {...rest}
      getTableProps={getTableProps}
      getRowProps={getRowProps}
      headerGroups={headerGroups}
      page={page}
      prepareRow={prepareRow}
      gotoPage={gotoPage}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      previousPage={previousPage}
      nextPage={nextPage}
      pageIndex={pageIndex}
      pageCount={pageCount}
      setPageSize={setPageSize}
      pageSize={pageSize}
      filters={filters}
      totalElements={total}
      setFilter={setFilter}
      customFilters={Filters}
    />
  );
} );
