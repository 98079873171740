import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavItem,
  Nav,
} from 'reactstrap';
import _ from 'lodash';

import { Intl } from 'components';
import UserActions from 'store/reducers/User';

const MainNavbar = React.memo( ( {
  sidebarCollapsed, toggleSidenav, logoutUser, routes,
} ) => (
  <>
    <Navbar className="navbar-top main-navbar navbar-expand fixed-top navbar-horizontal bg-primary">

      <Nav className="align-items-center" navbar>
        <NavItem className="d-lg-none">
          <div
            className={classnames(
              'pr-3 sidenav-toggler',
              { active: !sidebarCollapsed },
            )}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner ml-2">
              <i className="sidenav-toggler-line bg-white" />
              <i className="sidenav-toggler-line bg-white" />
              <i className="sidenav-toggler-line bg-white" />
            </div>
          </div>
        </NavItem>
      </Nav>

      <Nav className="align-items-center ml-auto pr-0 pr-3" navbar>
        <UncontrolledDropdown nav>
          <DropdownToggle className="nav-link pr-0" color="" tag="a">
            <span className="avatar avatar-sm rounded-circle bg-gradient-gray-dark">
              <i className="fa fa-user mr-0 text-primary" />
            </span>
          </DropdownToggle>
          <DropdownMenu right className="navbar-dropdown">
            {_.map( routes, ( route, index ) => (
              <div key={route.path}>
                {index > 0 && <DropdownItem divider /> }
                <DropdownItem
                  tag={NavLinkRRD}
                  to={route.layout + route.path}
                >
                  <i className={route.icon} />
                  <span><Intl id={route.name} /><Intl id={route.name} /></span>
                </DropdownItem>
              </div>
            ) )}

            <DropdownItem divider />
            <DropdownItem
              className="cursor-pointer"
              onClick={logoutUser}
            >
              <i className="ni ni-user-run" />
              <span><Intl id="logout" /></span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  </>
) );

const mapDispatchToProps = ( {
  logoutUser: UserActions.logoutUser,
} );

export default connect( null, mapDispatchToProps )( MainNavbar );
