import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import omit from 'lodash/omit';
import reducers from './reducers';
import sagas from './saga';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware( history );
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routeMiddleware, sagaMiddleware];

const blacklistTransform = createTransform(
  ( inboundState, key ) => {
    if ( key === 'settings' ) {
      return omit( inboundState, ['isLoading'] );
    }
    if ( key === 'user' ) {
      return omit( inboundState, ['roles'] );
    }
    return inboundState;
  },
);

const persistConfig = {
  key: 'mystic',
  whitelist: ['user', 'settings'],
  storage,
  transforms: [blacklistTransform],
};
const persistedReducer = persistReducer( persistConfig, reducers( history ) );

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    compose( applyMiddleware( ...middlewares ) ),
  );
  sagaMiddleware.run( sagas );

  const persistor = persistStore( store );
  return { store, persistor };
}

export { history };
