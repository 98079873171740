import React from 'react';
import { components } from 'react-select';

export const customStyles = {
  input: () => ( {
    margin: 0,
  } ),
  control: () => ( {} ),
};

export const IconOption = ( { data, ...rest } ) => (
  <components.Option data={data} {...rest}>
    {data.icon && <span className="mr-1">{data.icon}</span>}
    {data.label}
  </components.Option>
);

export const IconSingleValue = ( { data, ...rest } ) => (
  <components.SingleValue data={data} {...rest}>
    {data.icon && <span className="mr-1">{data.icon}</span>}
    {data.label}
  </components.SingleValue>
);

export const Clear = ( props ) => (
  <components.ClearIndicator {...props}>
    <i className="fas fa-times" style={{ fontSize: '.7rem' }} />
  </components.ClearIndicator>
);
