import React from 'react';
import classnames from 'classnames';
import { FormGroup, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import async from 'async';

import { Intl } from 'components';

const DropzoneInput = React.memo( ( {
  value, formGroupClass, translateValues, isInvalid, meta,
  preview = true, multiple = false, placeholder, label, labelClass, inputOnChange, onChange,
} ) => (
  <FormGroup className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}>
    {label
    && (
      <label className={classnames( labelClass, 'form-control-label' )}>
        <Intl id={label} />
      </label>
    )}
    <Dropzone
      accept="image/*"
      onDrop={( files ) => {
        if ( files && files[0] ) {
          if ( multiple ) {
            const formattedFiles = [...value] || [];
            async.each( files, ( file, cb ) => {
              const reader = new FileReader();
              reader.readAsDataURL( file );
              reader.onload = ( event ) => {
                formattedFiles.push( { file, fileDecoded: event.target.result } );
                cb();
              };
            }, ( error ) => {
              if ( !error ) {
                if ( inputOnChange ) inputOnChange( formattedFiles );
                if ( onChange ) onChange( formattedFiles );
              }
            } );
          } else {
            const reader = new FileReader();
            const file = files[0];
            reader.readAsDataURL( file );
            reader.onload = ( event ) => {
              if ( inputOnChange ) inputOnChange( { file, fileDecoded: event.target.result } );
              if ( onChange ) onChange( { file, fileDecoded: event.target.result } );
            };
          }
        }
      }}
    >
      {( { getRootProps, getInputProps } ) => (
        <div
          className="dropzone dropzone-single dz-clickable"
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {value && preview
            ? (
              <div className="dz-preview dz-preview-single">
                <div className="dz-preview-cover dz-image-preview">
                  <img className="dz-preview-img" src={value && value.fileDecoded ? value.fileDecoded : value} alt="" />
                </div>
              </div>
            ) : (
              <div className="dz-message">
                {placeholder || (
                  <>
                    <i className="fa fa-file-upload fa-4x" />
                  </>
                )}
              </div>
            )}
        </div>
      )}
    </Dropzone>
    {value
      ? (
        <Button
          color="primary"
          size="app-sm"
          block
          onClick={( e ) => {
            e.stopPropagation();
            if ( inputOnChange ) inputOnChange( null );
            if ( onChange ) onChange( null );
          }}
        >
          Clear
        </Button>
      ) : null}

    {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
  </FormGroup>
) );

export default DropzoneInput;
