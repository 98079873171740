import api from 'config/Api';
import { formatFormDataBody } from 'utils';

const baseEndpointList = 'administration/categories';
const baseEndpoint = 'administration/category';

export default {

  getCategories( params ) {
    return api.get( baseEndpointList, params );
  },

  getCategory( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveCategory( data ) {
    return api.post( baseEndpoint, data );
  },

  updateCategory( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  updateCategoryImage( id, data ) {
    return api.post( `${baseEndpoint}/${id}/image`, formatFormDataBody( data ) );
  },

  deleteCategory( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
