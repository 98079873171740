import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
} from 'reactstrap';
import logo from 'assets/img/brand/logo.png';

export default React.memo( ( { children } ) => (
  <Container fluid>
    <Row className="justify-content-center pt-5">
      <Col lg="4" md="7">
        <Card className="shadow-none border">
          <CardHeader className="text-center border-0">
            <img src={logo} alt="Mystic" />
          </CardHeader>
          <CardBody className="pt-2 card-body">
            {children}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
) );
