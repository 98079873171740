import React from 'react';
import { connect } from 'react-redux';

import SlideDashboardView from 'views/Slide/Dashboard';
import SlideService from 'api/Slide';
import { useDelete, useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import withModalEdit from 'hocs/withModalEdit';
import SlideEdit from '../Edit';

const SlideDashboard = ( {
  toEditId, modalOpened, dataSubmitted, reloadData, openEditModal, closeEditModal,
  toggleLoading, toggleErrorAlert,
} ) => {
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: SlideService.getSlides,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const deleteEntity = useDelete( {
    promise: SlideService.deleteSlide,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <SlideDashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
      />

      <SlideEdit
        entityId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( withModalEdit( SlideDashboard ) );
