import React from 'react';
import {
  useTable, usePagination, useSortBy, useFilters,
} from 'react-table';

import TableUI from '../utils/TableUI';
import ContainerTableUI from '../utils/ContainerTableUI';

export default React.memo( ( {
  data, columns, onlyTable, manualPagination, defaultPageSize = 10, ...rest
} ) => {
  const {
    getTableProps, headerGroups, page, prepareRow, gotoPage,
    canPreviousPage, previousPage, canNextPage, nextPage, pageCount, setPageSize,
    state: { pageIndex, pageSize, filters }, setFilter,
  } = useTable( {
    data,
    initialState: { pageSize: defaultPageSize },
    manualPagination,
    columns: React.useMemo(
      () => columns,
      [columns],
    ),
  }, useFilters, useSortBy, usePagination );

  return (
    onlyTable
      ? (
        <TableUI
          {...rest}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
        />
      )
      : (
        <ContainerTableUI
          {...rest}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setFilter={setFilter}
          filters={filters}
        />
      )
  );
} );
