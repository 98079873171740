import React from 'react';
import { Navbar } from 'reactstrap';

import { Logo } from 'components';

const AuthNavbar = React.memo( () => (
  <Navbar className="navbar-top main-navbar navbar-expand fixed-top navbar-horizontal auth-navbar">
    <Logo className="navbar-brand m-0" />
  </Navbar>
) );

export default AuthNavbar;
