import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Intl } from 'components';

export default React.memo( ( { title, route, onClick } ) => (
  route
    ? (
      <Button
        size="sm"
        color="primary"
        tag={Link}
        to={route}
      >
        <i className="fa fa-plus mr-1" />
        <Intl id={title} />
      </Button>
    )
    : (
      <Button
        size="sm"
        color="primary"
        onClick={() => onClick()}
      >
        <i className="fa fa-plus mr-1" />
        <Intl id={title} />
      </Button>
    )
) );
