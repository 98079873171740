import React from 'react';
import {
  ListGroupItem,
} from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';

export default ( { data, selectChannel, channelSelectedId } ) => (
  <ListGroupItem
    key={data.id}
    onClick={() => selectChannel( data )}
    className={classnames( 'list-group-item-action flex-column align-items-start py-2 pb-0 px-3 cursor-pointer', {
      'bg-gray-7': channelSelectedId === data.id,
    } )}
  >
    <div className="d-flex w-100 justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        {data.customer.imageUri
          ? (
            <img
              src={data.customer.imageUri}
              className="avatar avatar-sm rounded-circle mr-2"
              alt="..."
            />
          )
          : <div className="avatar avatar-sm mr-2"><i className="fa fa-user text-dark" /></div>}
        <h5 className="mb-0">
          {data.customer.name}
        </h5>
      </div>
      <small className="text-gray-2 text-right">
        {moment( Number( `${data.updatedAt}` ) ).format( 'DD/MM/YYYY HH:mm' )}
      </small>
    </div>
    <p className="text-sm mt-2 mb-0">
      {data.snippet}
    </p>
  </ListGroupItem>
);
