import api from 'config/Api';

const baseEndpointList = 'administration/bonds';
const baseEndpoint = 'administration/bond';

export default {

  getBonuses( params ) {
    params['filters[inRegister]'] = '0';
    return api.get( baseEndpointList, params );
  },

  getBonus( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveBonus( data ) {
    return api.post( baseEndpoint, data );
  },

  updateBonus( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteBonus( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
