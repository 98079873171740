import React from 'react';
import { Container, Button } from 'reactstrap';
import moment from 'moment';

import {
  AsyncTable, SimpleHeader,
} from 'components';

export default ( {
  data, isLoading, openPaymentModal, onFetchData = () => {},
} ) => (
  <>
    <SimpleHeader title="paymentRequests" />
    <Container fluid>
      <AsyncTable
        data={data.data}
        total={data.total}
        isLoading={isLoading}
        onFetchData={onFetchData}
        columns={[
          {
            Header: 'consultant',
            accessor: 'consultant.name',
          },
          {
            Header: 'comment',
            accessor: 'comment',
            getCellProps: () => {
              return {
                style: {
                  whiteSpace: 'unset', overflowWrap: 'anywhere',
                },
              };
            },
          },
          {
            Header: 'amount',
            accessor: 'amount',
          },
          {
            Header: 'status',
            accessor: 'status',
          },
          {
            Header: 'requestedAt',
            accessor: 'requestedAt',
            Cell: ( { cell } ) => ( cell.value ? moment( cell.value ).format( 'DD/MM/YY HH:mm' ) : null ),

          },
          {
            accessor: 'id',
            disableSorting: true,
            customWidth: 100,
            Header: '',
            Cell: ( { cell } ) => (
              cell.row.original.status === 'pending'
                ? (
                  <Button
                    size="sm"
                    color="warning"
                    // eslint-disable-next-line max-len
                    onClick={() => openPaymentModal( { id: cell.row.original.id, consultantId: cell.row.original.consultant.name } )}
                    className="px-4"
                  >
                    <i className="fas fa-hand-holding-usd mr-1" />
                  </Button>
                )
                : ''
            ),
          },
        ]}
      />
    </Container>
  </>
);
