import React from 'react';
import { Field } from 'react-final-form';

import PhoneInput from '../utils/PhoneInput';

const PhoneField = React.memo( ( {
  field, value, name, validate, ...rest
} ) => {
  if ( field ) {
    return (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError );

          return (
            <PhoneInput
              {...input}
              {...rest}
              value={input.value || ''}
              inputOnChange={input.onChange}
              inputName={input.name}
              focused={meta.active}
              meta={meta}
              isInvalid={isInvalid}
            />
          );
        }}
      </Field>
    );
  }
  return (
    <PhoneInput
      {...rest}
      value={value || ''}
      inputName={name}
    />
  );
} );

export default PhoneField;
