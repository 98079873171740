import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import CustomerDashboardView from 'views/Customer/Dashboard';
import CustomerService from 'api/Customer';
import { useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';

const CustomerDashboard = ( { history, toggleErrorAlert, toggleLoading } ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: CustomerService.getCustomers,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const toggleActive = useCallback( async ( user ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      CustomerService.updateCustomer( user.id, {
        enabled: user.enabled ? '0' : '1',
      } ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    setDataSubmitted( new Date().valueOf() );
  }, [toggleLoading, toggleErrorAlert] );

  return (
    <CustomerDashboardView
      data={data}
      isLoading={dataLoading}
      toggleActive={toggleActive}
      history={history}
      onFetchData={fetchData}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CustomerDashboard );
