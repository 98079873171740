import React from 'react';
import { Route, Switch } from 'react-router';

import Dashboard from './Dashboard';

const Bonus = ( { match } ) => (
  <Switch>
    <Route
      path={match.url}
      component={Dashboard}
    />
  </Switch>
);

export default Bonus;
