import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import PaymentDashboardView from 'views/Payment/Dashboard';
import PaymentService from 'api/Payment';
import { useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import Payment from '../Payment';

const PaymentDashboard = ( {
  openEditModal,
  toggleLoading, toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: PaymentService.getPaymentRequests,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );
  const toEditId = useRef( null );

  const [modalOpened, setModalOpened] = useState( false );

  const closePaymentModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const openPaymentModal = useCallback( ( data ) => {
    toEditId.current = data;
    setModalOpened( true );
  }, [] );
  /* const deleteEntity = useDelete( {
    promise: PaymentService.deleteBonus,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );
  */
  const reloadData2 = useCallback( () => {
    if ( modalOpened ) closePaymentModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closePaymentModal] );

  return (
    <>
      <PaymentDashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        openPaymentModal={openPaymentModal}
      />

      <Payment
        entityId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closePaymentModal}
        onReload={reloadData2}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( PaymentDashboard );
