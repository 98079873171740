import api from 'config/Api';

const baseEndpointList = 'administration/customers';
const baseEndpoint = 'administration/customer';

export default {

  getCustomers( params ) {
    return api.get( baseEndpointList, params );
  },

  getCustomer( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  getCustomerOperations( id, params ) {
    return api.get( `${baseEndpoint}/${id}/operations`, params );
  },

  updateCustomer( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
};
