import Login from 'containers/Login';

export default [
  {
    component: Login,
    path: '/login',
    layout: '/auth',
  },
  {
    component: Login,
    path: '/recover-password/:token',
    layout: '/auth',
  },
  {
    component: Login,
    path: '/forgot-password',
    layout: '/auth',
  },
  {
    component: Login,
    path: '/sign-up/:token',
    layout: '/auth',
  },
  {
    component: Login,
    path: '/sign-up',
    layout: '/auth',
  },
];
