import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import HelpView from 'views/Help';
import SettingsService from 'api/Settings';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import { useFetch } from 'hooks';

const Help = ( { toggleLoading, toggleErrorAlert } ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: SettingsService.getSetting,
    format: ( dataToFormat ) => ( {
      htmlHelp: EditorState.createWithContent(
        ContentState.createFromBlockArray( htmlToDraft( dataToFormat.htmlHelp ).contentBlocks ),
      ),
    } ),
    toggleErrorAlert,
  } );

  const submitForm = useCallback( async ( formData ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      SettingsService.updateSettings( {
        htmlHelp: formData.htmlHelp ? draftToHtml( convertToRaw( formData.htmlHelp.getCurrentContent() ) ) : '',
      } ),
    );
    toggleLoading( false );
    if ( !response.ok ) return errors;
  }, [toggleLoading] );

  return (
    <HelpView
      data={data}
      isLoading={dataLoading}
      submitForm={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( Help );
