import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthNavbar } from 'components';

import { publicRoutes } from 'config/Routes';

class Auth extends React.Component {
  componentDidUpdate( e ) {
    if ( e.history.pathname !== e.location.pathname ) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContent.scrollTop = 0;
    }
  }

  getRoutes = ( routes ) => routes.map( ( prop ) => (
    <Route
      path={prop.layout + prop.path}
      component={prop.component}
      key={prop.layout + prop.path}
    />
  ) );

  render() {
    const { location } = this.props;

    return (
      <>
        <AuthNavbar pathname={location.pathname} />
        <div className="main-content main-content-auth" ref={( el ) => { this.mainContent = el; }}>
          <Switch>{this.getRoutes( publicRoutes )}</Switch>
        </div>
      </>
    );
  }
}

export default Auth;
