import { createReducer, createActions } from 'reduxsauce';
import { storage } from '../../utils';

export const INITIAL_STATE = {};

const { Types, Creators } = createActions( {
  loginUser: ['data'],
  updateUser: ['data'],
  logoutUser: null,
} );

const loginUser = ( state, { data } ) => ( { ...state, ...data } );
const updateUser = ( state, { data } ) => ( { ...state, ...data } );

const logoutUser = () => {
  storage.clear( 'mystic:token' );
  return {};
};

export const reducer = createReducer( INITIAL_STATE, {
  [Types.LOGIN_USER]: loginUser,
  [Types.LOGOUT_USER]: logoutUser,
  [Types.UPDATE_USER]: updateUser,
} );

export const UserTypes = Types;
export default Creators;
