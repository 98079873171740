import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import {
  FormGroup, InputGroup, InputGroupText, InputGroupAddon as InputGroupAddonComp,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';

import { Intl } from 'components';

const DatepickerInput = React.memo( ( {
  label, value, inputName, size = 'xsm', placeholder, intl, translateValues, isInvalid,
  focused, meta, startDateField, endDateField, formChange, translate = true, showErrors = true,
  classNames = {}, clearable, inputOnChange, onChange, ...rest
} ) => (
  <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
    {label
    && (
      <label className={classnames( classNames.label, 'form-control-label' )}>
        {translate ? <Intl id={label} /> : label}
      </label>
    )}

    <div>
      <InputGroup className={classnames( 'input-group-merge', { focused, clearable } )}>
        <ReactDatetime
          timeFormat={false}
          {...rest}
          inputProps={{
            disabled: rest.disabled,
            readOnly: true,
            placeholder: placeholder ? intl.formatMessage( { id: placeholder } ) : null,
            className: classnames( 'form-control', {
              'is-invalid': isInvalid,
              'form-control-lg': size === 'lg',
              'form-control-sm': size === 'sm',
              'form-control-xsm': size === 'xsm',
              'text-red': meta.error === 'INVALID',
            } ),
          }}
          value={value || ''}
          onChange={( e ) => {
            if ( inputOnChange ) inputOnChange( e );
            if ( onChange ) onChange( e );
            if ( e && endDateField && endDateField.value
              && e.isSameOrAfter( endDateField.value ) && formChange ) {
              formChange( e );
            }
          }}
          isValidDate={( currentDate ) => ( startDateField && startDateField.value
            ? currentDate.isAfter( startDateField.value ) : true )}
        />

        {!!clearable && value
        && (
          <InputGroupAddonComp
            addonType="append"
            onClick={() => {
              if ( inputOnChange ) inputOnChange( null );
              if ( onChange ) onChange( null );
            }}
            className={classnames( 'rdt-clear cursor-pointer', { 'is-invalid': isInvalid } )}
          >
            <InputGroupText>
              <i className="app-icon-close" style={{ fontSize: '.7rem' }} />
            </InputGroupText>
          </InputGroupAddonComp>
        )}
      </InputGroup>

      {isInvalid && showErrors
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
    </div>
  </FormGroup>
) );

export default injectIntl( DatepickerInput );
