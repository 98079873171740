import React from 'react';
import { Container, Badge, Button } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable, DetailCard, Intl,
} from 'components';
import { formatPhone } from 'utils';

export default ( {
  data, isLoading, operations, operationsLoading, onFetchOperations, createOperation,
} ) => (
  <>
    <Container fluid className="mt-4">
      <DetailCard isLoading={isLoading} classNames={{ body: 'py-2' }}>
        <div className="text-right">
          <Button
            size="sm"
            color="warning"
            onClick={createOperation}
            className="px-4"
          >
            <i className="fas fa-hand-holding-usd mr-1" />
            <Intl id="pay" />
          </Button>
        </div>
        {data.imageUri
          ? (
            <div className="text-center">
              <img
                alt="..."
                className="avatar avatar-xl"
                src={data.imageUri}
                style={{ borderRadius: 50 }}
              />
            </div>
          ) : (
            <div className="text-center">
              <div
                className="avatar avatar-xl"
                style={{ borderRadius: 50 }}
              >
                <i className="fa fa-user fa-2x text-dark" />
              </div>
            </div>
          )}
        <div className="pt-2 text-center">
          <h3 className="mb-1">
            {data.name}
          </h3>
          {!!data.email
          && (
            <h4 className=" mb-1 font-weight-light">
              <i className="fas fa-envelope mr-2" />
              {data.email}
            </h4>
          )}
          {!!data.phoneNumber
          && (
            <h4 className="  font-weight-light">
              <i className="fas fa-phone  mr-2" />
              {formatPhone(
                data.codeCountry
                  ? data.codeCountry + data.phoneNumber : data.phoneNumber,
              )}
            </h4>
          )}
          {!!data.timeZone
          && (
            <h4 className="  font-weight-light">
              <i className="fas fa-clock  mr-2" />
              {data.timeZone}
            </h4>
          )}
          <Badge color="info" pill>
            <Intl id="balance" />
            {': $'}
            {data.totalAmount || 0}
          </Badge>
        </div>
      </DetailCard>

      <AsyncTable
        titleContent={<Intl id="operations" />}
        data={operations.data}
        total={operations.total}
        isLoading={operationsLoading}
        onFetchData={onFetchOperations}
        columns={[
          {
            Header: 'type',
            accessor: 'type',
          },
          {
            Header: 'tariff',
            accessor: 'price',
          },
          {
            Header: 'quantityM',
            accessor: 'quantity',
          },
          {
            Header: 'profit',
            accessor: 'consultantAmount',
          },
          {
            Header: 'createdAt',
            accessor: 'createdAt',
            Cell: ( { cell } ) => (
              cell.value ? moment( cell.value ).format( 'DD/MM/YYYY h:mm A' ) : ''
            ),
          },
        ]}
      />

    </Container>
  </>
);
