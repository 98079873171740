import React from 'react';
import { Container } from 'reactstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  SimpleHeader, Form, FormActionsBtns, DetailCard, Wysiwyg,
} from 'components';

export default ( { data, isLoading, submitForm } ) => (
  <>
    <SimpleHeader title="help" />
    <Container fluid>
      <DetailCard isLoading={isLoading}>
        <Form
          initialValues={data}
          onSubmit={submitForm}
          showErrorsInFooter
          body={() => (
            <>
              <Wysiwyg field="htmlHelp" />
            </>
          )}
          footer={(
            <FormActionsBtns />
          )}
        />
      </DetailCard>
    </Container>
  </>
);
