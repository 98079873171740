import api from 'config/Api';

export default {

  loginUser( data ) {
    return api.post( 'oauth/v2/token', {
      ...data,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: 'admin_credentials',
      scope: null,
    } );
  },
};
