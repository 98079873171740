import api from 'config/Api';

const baseEndpoint = 'administration/message-express';

export default {

  getMessages( channelId, params ) {
    return api.get( `${baseEndpoint}/${channelId}`, params );
  },

  sendMessages( channelId, data ) {
    return api.post( `${baseEndpoint}/${channelId}`, data );
  },
};
