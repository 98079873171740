import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, Checkbox,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  entityId, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={entityId ? 'editPackage' : 'newPackage'}
    showErrorsInFooter
    isLoading={isLoading}
    size="md"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <Row>
      <Col>
        <TextField field="name" label="name" validate={required} />
        <TextField field="storageKey" label="storageKey" validate={required} />
        <TextField field="credit" label="credit" type="number" validate={required} />
        <TextField field="extraCredit" label="extraCredit" type="number" />
        <Checkbox field="active" label="active" horizontal />
      </Col>
    </Row>
  </ModalForm>
) );
