import { createReducer, createActions } from 'reduxsauce';

const rltLocale = ['ar'];
export const INITIAL_STATE = {
  sidebarCollapsed: false,
  isDirectionRTL: false,
  isLoading: false,
  locale: {
    locale: 'es',
    name: 'spanish',
  },
};

const { Types, Creators } = createActions( {
  toggleSidebar: null,
  toggleAlert: ['msg', 'alertType'],
  toggleInfoAlert: ['msg'],
  toggleErrorAlert: ['msg'],
  toggleLoading: ['isLoading'],
  switchLanguage: ['locale'],
} );

const toggleSidebar = ( state ) => ( { ...state, sidebarCollapsed: !state.sidebarCollapsed } );
const switchLanguage = ( state, { locale } ) => ( {
  ...state,
  locale,
  isDirectionRTL: rltLocale.includes( locale.locale ),
} );
const toggleAlert = ( state, { msg, alertType } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, alertType },
} );
const toggleErrorAlert = ( state, { msg } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, alertType: 'danger' },
} );
const toggleInfoAlert = ( state, { msg } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, alertType: 'info-app' },
} );
const toggleLoading = ( state, { isLoading } ) => ( { ...state, isLoading } );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.TOGGLE_SIDEBAR]: toggleSidebar,
  [Types.SWITCH_LANGUAGE]: switchLanguage,
  [Types.TOGGLE_ALERT]: toggleAlert,
  [Types.TOGGLE_INFO_ALERT]: toggleInfoAlert,
  [Types.TOGGLE_ERROR_ALERT]: toggleErrorAlert,
  [Types.TOGGLE_LOADING]: toggleLoading,
} );

export const SettingTypes = Types;
export default Creators;
