import React from 'react';
import { Container } from 'reactstrap';

import {
  AsyncTable, AddBtn, SimpleHeader, ActiveIcon, TableEditBtn, Intl,
} from 'components';

export default ( {
  data, isLoading, onOpenEditModal, onFetchData = () => {},
} ) => (
  <>
    <SimpleHeader title="bonuses" />
    <Container fluid>
      <AsyncTable
        data={data.data}
        total={data.total}
        isLoading={isLoading}
        rightContent={<AddBtn title="newBonus" onClick={onOpenEditModal} />}
        onFetchData={onFetchData}
        columns={[
          {
            Header: 'code',
            accessor: 'code',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value || '-'}
                onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
              />
            ),
          },
          {
            Header: 'fee',
            accessor: 'fee',
          },
          // {
          //   Header: 'inRegister',
          //   accessor: 'inRegister',
          //   Cell: ( { cell } ) => <ActiveIcon active={cell.value} />,
          // },
          {
            Header: 'useMode',
            accessor: 'useMode',
            Cell: ( { cell } ) => ( cell.value ? <Intl id={cell.value} /> : null ),

          },
          /* {
            accessor: 'id',
            disableSorting: true,
            customWidth: 100,
            Header: '',
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          }, */
        ]}
      />
    </Container>
  </>
);
