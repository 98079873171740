import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import {
  FormGroup, InputGroup, Input, Col, Row,
} from 'reactstrap';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';

import { Intl } from 'components';
import InputGroupAddon from '../InputGroupAddon';

const PhoneInput = React.memo( ( {
  label, value, inputOnChange, inputName, size = 'sm', onChange, prependIcon, appendIcon,
  classNames = {}, intl, translateValues, isInvalid, replacePrefix, focused, meta,
  horizontal, ...rest
} ) => {
  const formatValue = ( val ) => ( formatIncompletePhoneNumber( val )
    ? formatIncompletePhoneNumber( val ) : val );

  return (
    <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
      <Row>
        <Col md={horizontal ? 4 : 12} className="pr-md-0 pb-2 pb-md-0">
          {label
          && (
            <div className={classnames( { 'bg-gray-3 p-2': horizontal } )}>
              <label className={classnames( 'form-control-label' )}>
                <Intl id={label} />
              </label>
            </div>
          )}
        </Col>
        <Col md={horizontal ? 8 : 12}>
          <InputGroup className={classnames( 'input-group-merge', { focused } )}>
            {prependIcon || classNames.prependIcon ? (
              <InputGroupAddon
                addonType="prepend"
                isInvalid={isInvalid}
                icon={prependIcon}
                iconClass={classNames.prependIcon}
              />
            ) : null}

            <Input
              {...rest}
              placeholder={rest.placeholder ? intl.formatMessage( { id: rest.placeholder } ) : null}
              className={classnames( {
                'is-invalid': isInvalid,
                'form-control-lg': size === 'lg',
                'form-control-sm': size === 'sm',
              } )}
              value={value ? formatValue( value ) : ''}
              onChange={( e ) => {
                let val = e.target.value;
                if ( val && val[0] !== '+' ) {
                  val = `+${val}`;
                }
                if ( inputOnChange ) inputOnChange( val );
                if ( onChange ) onChange( val );
              }}
            />

            {appendIcon || classNames.appendIcon ? (
              <InputGroupAddon
                addonType="append"
                isInvalid={isInvalid}
                icon={appendIcon}
                iconClass={classNames.appendIcon}
              />
            ) : null}
          </InputGroup>

          {isInvalid
          && (
            <div className="invalid-feedback">
              <Intl id={meta.error || meta.submitError} values={translateValues} />
            </div>
          )}
        </Col>
      </Row>
    </FormGroup>
  );
} );

export default injectIntl( PhoneInput );
