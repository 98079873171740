import api from 'config/Api';

const baseEndpoint = 'administration/dashboard';

export default {

  getData( params ) {
    return api.get( baseEndpoint, params );
  },
};
