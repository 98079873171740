import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, Dropzone, Checkbox,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  entityId, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={entityId ? 'editCategory' : 'newCategory'}
    showErrorsInFooter
    isLoading={isLoading}
    size="md"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <Row>
      <Col md={4}>
        <Dropzone
          field="imageFile"
          label="avatar"
          validate={required}
        />
      </Col>
      <Col>
        <TextField field="name" label="name" validate={required} />
        <Checkbox field="active" label="active" horizontal />
      </Col>
    </Row>
  </ModalForm>
) );
