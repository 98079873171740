import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import DashboardView from 'views/Dashboard';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// eslint-disable-next-line camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import moment from 'moment';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import DashboardService from 'api/Dashboard';
import _ from 'lodash';
import { handlePromise } from 'utils';

am4core.useTheme( am4themes_animated );

const Dashboard = ( { toggleLoading, toggleErrorAlert } ) => {
  const [filter, setFilter] = useState( 'today' );
  const [data, setData] = useState( null );
  const chartRef = useRef();

  const formatChartData = useCallback( ( chartData ) => {
    const chart = am4core.create( 'chartdiv', am4charts.XYChart );

    _.keys( chartData ).forEach( ( item ) => {
      chart.data.push( { date: item, name: item, value: chartData[item] } );
    } );


    const dateAxis = chart.xAxes.push( new am4charts.DateAxis() );
    dateAxis.renderer.grid.template.location = 0;

    const valueAxis = chart.yAxes.push( new am4charts.ValueAxis() );
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;

    const series = chart.series.push( new am4charts.LineSeries() );
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';

    series.tooltipText = '{valueY.value}';
    chart.cursor = new am4charts.XYCursor();

    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push( series );
    chart.scrollbarX = scrollbarX;

    chartRef.current = chart;
  }, [] );

  const getData = useCallback( async ( filterValue ) => {
    toggleLoading( true );
    const params = {};

    switch ( filterValue ) {
      case 'today':
        params.start = moment().format( 'YYYY-MM-DD' );
        params.end = moment().add( 1, 'day' ).format( 'YYYY-MM-DD' );
        break;
      case 'lastMonth':
        params.start = moment().format( 'YYYY-MM-DD' );
        params.end = moment().subtract( 1, 'month' ).format( 'YYYY-MM-DD' );
        break;
      case 'lastYear':
        params.start = moment().format( 'YYYY-MM-DD' );
        params.end = moment().subtract( 1, 'year' ).format( 'YYYY-MM-DD' );
        break;
      default:
        break;
    }
    const [error, response, responseData] = await handlePromise(
      DashboardService.getData( params ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( error );
    setData( responseData );
    formatChartData( responseData?.conn || [] );
  }, [formatChartData, toggleLoading, toggleErrorAlert] );

  const filterData = useCallback( ( value ) => {
    setFilter( value );
    getData( value );
  }, [getData] );

  useEffect( () => {
    getData( filter );

    return () => {
      if ( chartRef.current ) {
        chartRef.current.dispose();
      }
    };
  }, [] ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardView
      data={data}
      filter={filter}
      setFilter={filterData}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( Dashboard );
