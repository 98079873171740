import React from 'react';
import { connect } from 'react-redux';

import CreditEdittView from 'views/Credit/Edit';
import { useFetch, useSubmit } from 'hooks';
import CreditService from 'api/Credit';
import SettingsActions from 'store/reducers/Settings';

const CreditEdit = ( {
  entityId, modalOpened, onReload, onCloseModal, toggleLoading, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CreditService.getCredit( entityId ),
    toggleErrorAlert,
    conditional: !!entityId && modalOpened,
    deps: [entityId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( entityId
      ? CreditService.updateCredit( entityId, dataToSend )
      : CreditService.saveCredit( dataToSend ) ),
    toggleLoading,
    format: ( dataToFormat ) => ( {
      name: dataToFormat.name,
      storageKey: dataToFormat.storageKey,
      credit: dataToFormat.credit,
      extraCredit: dataToFormat.extraCredit ? dataToFormat.extraCredit : 0,
      active: dataToFormat.active ? '1' : '0',
    } ),
    deps: [entityId],
    callback: onReload,
  } );

  return (
    <CreditEdittView
      entityId={entityId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
} );

export default connect( null, mapDispatchToProps )( CreditEdit );
