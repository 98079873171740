import React from 'react';
import { Modal } from 'reactstrap';
import classnames from 'classnames';

import { Intl } from 'components';

export default React.memo( ( {
  title, open, children, footer, isLoading,
  size = 'lg', bodyClassName, onClose, translateTitle = true,
} ) => (
  <Modal
    className="modal-dialog-centered app-modal"
    size={size}
    isOpen={open}
    toggle={onClose}
    zIndex={5000}
    backdrop="static"
    contentClassName={classnames( {
      'app-component-loading': isLoading,
    } )}
  >
    <div className="modal-header">
      <span className="modal-title font-weight-bold text-gray-7">
        {translateTitle ? <Intl id={title} /> : title}
      </span>
      <button
        aria-label="Close"
        className="close text-sm"
        data-dismiss="modal"
        type="button"
        onClick={onClose}
      >
        <i className="fa fa-times" />
      </button>
    </div>
    <div className={classnames( 'modal-body py-0', bodyClassName )}>
      {children}
    </div>

    {footer
      ? (
        <div className="modal-footer justify-content-center">
          {footer}
        </div>
      ) : null}
  </Modal>
) );
