import _ from 'lodash';
import { isValidNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

// eslint-disable-next-line no-nested-ternary
const email = ( val ) => ( val ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( val ) ? null : 'fieldError.email' : null );

const equalTo = ( fieldToValue ) => ( fieldFromValue, values ) => ( fieldFromValue === values[fieldToValue] ? null : 'fieldError.equalTo' );

const required = ( val ) => {
  if ( _.isBoolean( val ) ) {
    return val ? null : 'fieldError.required';
  }
  return val && val.toString().length ? null : 'fieldError.required';
};

// eslint-disable-next-line no-nested-ternary
const lessThanOrEqual = ( min ) => ( val ) => ( val ? val <= min ? null : 'fieldError.lessThanOrEqual' : null );

// eslint-disable-next-line no-nested-ternary
const lessThanOrEqualChar = ( min ) => ( val ) => ( val ? val.toString().length <= min ? null : 'fieldError.lessThanOrEqualChar' : null );


// eslint-disable-next-line no-nested-ternary
const greaterThanOrEqual = ( min ) => ( val ) => ( val ? val >= min ? null : 'fieldError.greaterThanOrEqual' : null );

const validUrl = ( val ) => {
  if ( val ) {
    return val.match( /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ ) ? null : 'fieldError.url';
  }
  return null;
};

const validPhone = ( val ) => {
  if ( val ) {
    return isValidNumber( val ) ? null : 'fieldError.validPhone';
  }
  return null;
};

const validPhoneCountry = ( val ) => {
  if ( val && isValidNumber( val ) ) {
    const code = parsePhoneNumberFromString( val );
    return code && code.countryCallingCode ? null : 'fieldError.validPhoneCountry';
  }
  return null;
};

const composeValidators = ( ...validators ) => ( value, values ) => (
  validators.reduce( ( error, validator ) => error || validator( value, values ), undefined )
);

export {
  email,
  equalTo,
  required,
  validUrl,
  composeValidators,
  lessThanOrEqual,
  greaterThanOrEqual,
  validPhone,
  validPhoneCountry,
  lessThanOrEqualChar,
};
