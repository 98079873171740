import React from 'react';
import { connect } from 'react-redux';

import ConsultantDashboardView from 'views/Consultant/Dashboard';
import ConsultantService from 'api/Consultant';
import { useDelete, useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import withModalEdit from 'hocs/withModalEdit';
import ConsultantEdit from '../Edit';

const ConsultantDashboard = ( {
  toEditId, modalOpened, dataSubmitted, reloadData, openEditModal, closeEditModal,
  toggleLoading, toggleErrorAlert, history,
} ) => {
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ConsultantService.getConsultants,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const deleteEntity = useDelete( {
    promise: ConsultantService.deleteConsultant,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <ConsultantDashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
        goToDetail={( id ) => history.push( `/consultants/${id}` )}
      />

      <ConsultantEdit
        entityId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( withModalEdit( ConsultantDashboard ) );
