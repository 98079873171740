import React from 'react';
import classnames from 'classnames';

export default React.memo( ( { className, title, onClick } ) => (
  <span
    className={classnames( 'text-primary cursor-pointer', className )}
    onClick={() => onClick()}
  >
    {title}
  </span>
) );
