import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';

const SendMessage = ( { sendTextMessage } ) => {
  const [message, setMessage] = useState();
  return (
    <Row>
      <Col>
        <textarea
          className="chat-send-message"
          value={message || ''}
          onChange={( val ) => setMessage( val.target.value )}
          placeholder="Type your message..."
          onKeyDown={( e ) => {
            if ( e.keyCode === 13 && e.shiftKey === false ) {
              e.preventDefault();
              sendTextMessage( message );
            }
          }}
        />
      </Col>

      <Col className="col-auto pr-0">
        <Button
          color="link"
          type="button"
          onClick={() => sendTextMessage( message )}
        >
          Send
        </Button>
      </Col>
    </Row>
  );
};

export default SendMessage;
