import React from 'react';
import classnames from 'classnames';

export default ( ( { message } ) => (
  <div
    className={classnames( {
      'chat-messages-message-right mr-3': !message.system && !message.customer,
      'chat-messages-message-left': !!message.customer,
      'chat-messages-message-center': message.system && !message.customer,
    } )}
  >
    <div className="chat-messages-message-container">
      <div className="row no-gutters align-items-center">
        <div className="col order-1 order-sm-0">
          <div
            key={message.id}
            className="chat-messages-message-content"
          >
            {!!message.body
              && (
                <div className="col-xs-12 chat-messages-message-text">
                  <span>{message.body}</span>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  </div>
) );
