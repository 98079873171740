import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { Intl } from 'components';

const SimpleHeader = React.memo( ( {
  title, translateTitle = true, parentLinks = [], rightContent, containerFluid = true,
} ) => {
  const formattedName = ( link ) => ( link.translate === false
    ? link.name : <Intl id={link.name} /> );
  const isRoot = !parentLinks || !parentLinks.length;

  return (
    <div className="header simple-header">
      <Container fluid={containerFluid}>
        <div className="header-body">
          <Row className="align-items-center py-3">
            <Col md={8}>
              {title
              && (
                <h3 className="title font-weight-normal d-inline-block mb-0 mr-md-3 text-uppercase">
                  {translateTitle ? <Intl id={title} /> : title}
                </h3>
              )}
              <Breadcrumb
                className="d-none d-md-inline-block"
                listClassName="breadcrumb-links"
              >
                {!isRoot && (
                  <BreadcrumbItem>
                    <Link to="/">
                      <i className="fas fa-home" />
                    </Link>
                  </BreadcrumbItem>
                ) }
                {parentLinks.map( ( link, index ) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <BreadcrumbItem key={index}>
                    {link.href
                      ? <Link to={link.href}>{formattedName( link )}</Link>
                      : <span className="text-primary">{formattedName( link )}</span>}
                  </BreadcrumbItem>
                ) )}
              </Breadcrumb>
            </Col>
            <Col md={4} className="text-right">
              {rightContent}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
} );

export default SimpleHeader;
