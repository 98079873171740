import React from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { Intl } from 'components';
import {
  customStyles, IconOption, IconSingleValue, Clear,
} from '../SelectCustomComponents';

const SelectInput = React.memo( ( {
  label, isInvalid, inputName, multiple, placeholder, size = 'sm', value,
  labelKey = 'name', options = [], idKey = 'id', translateOptions, intl, meta, translateValues, showErrors = true,
  classNames = {}, onChange, inputOnChange, ...rest
} ) => {
  const primitiveValue = ( data ) => ( data && _.get( data, idKey ) ? _.get( data, idKey ) : data );
  const findOption = ( val ) => _.find( options, ( o ) => ( _.get( o, idKey ) === val ) ) || {};

  const formatMultipleValues = () => _.map( value, ( item ) => {
    const simpleValue = primitiveValue( item );
    const option = findOption( simpleValue );
    return {
      value: simpleValue,
      // eslint-disable-next-line no-nested-ternary
      label: translateOptions ? _.get( option, labelKey )
        ? intl.formatMessage( { id: _.get( option, labelKey ) } )
        : _.get( option, labelKey )
        : _.get( option, labelKey ),
      icon: option.icon,
    };
  } );

  const formatSingleValue = () => {
    const simpleValue = primitiveValue( value );
    const option = findOption( simpleValue );
    return {
      value: simpleValue,
      // eslint-disable-next-line no-nested-ternary
      label: translateOptions ? _.get( option, labelKey )
        ? intl.formatMessage( { id: _.get( option, labelKey ) } )
        : _.get( option, labelKey )
        : _.get( option, labelKey ),
      icon: option.icon,
    };
  };

  const getValue = ( val ) => ( multiple ? _.map( val, 'value' ) : val.value );

  return (
    <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
      {label
      && (
        <label className={classnames( classNames.label, 'form-control-label' )}>
          <Intl id={label} />
        </label>
      )}
      <Select
        {...rest}
        isMulti={multiple}
        placeholder={placeholder ? intl.formatMessage( { id: placeholder } ) : null}
        styles={customStyles}
        classNamePrefix="custom-select"
        className={classnames( 'custom-select-container form-control', {
          'is-invalid': isInvalid,
          'form-control-lg': size === 'lg',
          'form-control-sm': size === 'sm',
          'form-control-xsm': size === 'xsm',
        } )}
        onChange={( val ) => {
          if ( multiple || !val || val.value !== value ) {
            const valueVal = val ? getValue( val ) : null;
            if ( inputOnChange ) inputOnChange( valueVal );
            if ( onChange ) onChange( valueVal, value, val );
          }
        }}
        value={multiple ? formatMultipleValues() : value && formatSingleValue()}
        options={_.map( options, ( option ) => ( {
          value: _.get( option, idKey ),
          // eslint-disable-next-line no-nested-ternary
          label: translateOptions ? _.get( option, labelKey )
            ? intl.formatMessage( { id: _.get( option, labelKey ) } )
            : _.get( option, labelKey )
            : _.get( option, labelKey ),
          icon: option.icon,
          original: option,
        } ) )}
        components={{
          Option: IconOption,
          SingleValue: IconSingleValue,
          ClearIndicator: Clear,
        }}
      />

      {isInvalid && showErrors
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
    </FormGroup>
  );
} );


export default injectIntl( SelectInput );
