import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Footer, MainNavbar, Sidebar,
} from 'components';
import SettingsActions from 'store/reducers/Settings';
import { privateRoutes } from 'config/Routes';
import ChannelService from 'api/Channel';
import PaymentService from 'api/Payment';
import { handlePromise } from 'utils';

class Private extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      expressCount: 0,
      pendingPayCount: 0,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { sidebarCollapsed } = this.props;

    if ( sidebarCollapsed ) {
      document.body.classList.remove( 'g-sidenav-pinned' );
      document.body.classList.remove( 'g-sidenav-show' );
      document.body.classList.add( 'g-sidenav-hidden' );
    }

    this.routes = this.getRoutes( privateRoutes );
  }

  componentDidMount() {
    this.getData();
    this.getDataPendingPayment();
  }

  componentDidUpdate( prevProps ) {
    // eslint-disable-next-line react/destructuring-assignment
    if ( prevProps.location.pathname !== this.props.location.pathname ) {
      this.getData();
      this.getDataPendingPayment();
    }
    if ( prevProps.history.pathname !== prevProps.location.pathname ) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContent.scrollTop = 0;
    }
  }

  getRoutes = ( routesList ) => routesList.map( ( prop ) => {
    if ( prop.views ) {
      return this.getRoutes( prop.views );
    }
    if ( prop.component ) {
      return (
        <Route
          exact={prop.exact}
          path={prop.path}
          component={prop.component}
          key={prop.path}
        />
      );
    }
    return null;
  } );

  toggleSidenav = () => {
    const { sidebarCollapsed, toggleSidebar } = this.props;
    if ( sidebarCollapsed ) {
      toggleSidebar();
      document.body.classList.add( 'g-sidenav-pinned' );
      document.body.classList.remove( 'g-sidenav-hidden' );
    } else {
      toggleSidebar();
      document.body.classList.remove( 'g-sidenav-pinned' );
      document.body.classList.add( 'g-sidenav-hidden' );
    }
  };

  getData = async () => {
    const [, response, responseData] = await handlePromise(
      ChannelService.getChannels( {
        limit: -1,
        offset: 10,
        'filters[status]': 'requested',
        'sortby[updatedAt]': 'DESC',
      } ),
    );
    if ( !response.ok ) return;
    this.setState( { expressCount: responseData.total } );
  };

  getDataPendingPayment = async () => {
    const [, response, responseData] = await handlePromise(
      PaymentService.getPaymentRequests( {
        limit: -1,
        offset: 10,
        'filters[status]': 'pending',
      } ),
    );
    if ( !response.ok ) return;
    this.setState( { pendingPayCount: responseData.total } );
  };

  render() {
    const { sidebarCollapsed } = this.props;
    const { expressCount, pendingPayCount } = this.state;

    return (
      <>
        <Sidebar
          routes={privateRoutes}
          toggleSidenav={this.toggleSidenav}
          sidebarCollapsed={sidebarCollapsed}
          expressCount={expressCount}
          pendingPayCount={pendingPayCount}

        />

        <MainNavbar
          toggleSidenav={this.toggleSidenav}
          sidebarCollapsed={sidebarCollapsed}
        />

        <div
          className="main-content main-content-wrapper"
          ref={( el ) => { this.mainContent = el; }}
          onClick={this.closeSidenav}
        >
          <Switch>
            {this.routes}
            <Redirect from="*" to="/" />
          </Switch>
          <Footer />
        </div>
        {!sidebarCollapsed ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( {
  sidebarCollapsed: settings.sidebarCollapsed,
} );

const mapDispatchToProps = ( {
  toggleSidebar: SettingsActions.toggleSidebar,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Private );
