import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { FormGroup } from 'reactstrap';

import { Intl } from 'components';
import { Editor } from 'react-draft-wysiwyg';

const WysiwygInput = React.memo( ( {
  label, value, validateTranslateValues, isInvalid, meta, translate = true,
  classNames = {}, onChange, inputOnChange,
} ) => (
  <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
    {label
    && (
      <label className={classnames( classNames.label, 'form-control-label' )}>
        {translate ? <Intl id={label} /> : label}
      </label>
    )}

    <Editor
      editorState={value}
      onEditorStateChange={( val ) => {
        if ( inputOnChange ) inputOnChange( val );
        if ( onChange ) onChange( val );
      }}
      wrapperClassName="wysiwyg-wrapper"
      toolbar={{
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
      }}
    />

    {isInvalid
    && (
      <div className="invalid-feedback">
        <Intl id={meta.error || meta.submitError} values={validateTranslateValues} />
      </div>
    )}
  </FormGroup>
) );

export default injectIntl( WysiwygInput );
