import api from 'config/Api';

const baseEndpoint = 'administration/setting';

export default {

  getSetting() {
    return api.get( baseEndpoint );
  },

  updateSettings( data ) {
    return api.put( baseEndpoint, data );
  },
};
