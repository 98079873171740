import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, Dropzone, Phone, Select,
} from 'components';
import {
  required, validPhoneCountry, validPhone, composeValidators,
} from 'config/InputErrors';
import timezones from 'utils/timezones.json';

export default React.memo( ( {
  entityId, data, isLoading, open, categories, categoriesLoading, onClose, onSubmit,
  rates, ratesLoading,
} ) => (
  <ModalForm
    initialValues={data}
    title={entityId ? 'editConsultant' : 'newConsultant'}
    showErrorsInFooter
    isLoading={isLoading}
    size="lg"
    bodyClassName="overflow-visible"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <Row>
      <Col md={3}>
        <Dropzone
          field="imageFile"
          label="avatar"
          validate={required}
        />
      </Col>

      <Col>
        <Row>
          <Col>
            <TextField field="name" label="name" validate={required} />
          </Col>
          <Col>
            <Phone
              field="phoneNumber"
              label="phoneNumber"
              validate={composeValidators( required, validPhone, validPhoneCountry )}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextField field="email" label="email" validate={required} />
          </Col>
          <Col>
            <Select
              field="timeZone"
              label="timezone"
              options={timezones}
              idKey="value"
              labelKey="label"
            />
          </Col>
        </Row>

        {!entityId
          ? (
            <Row>
              <Col>
                <TextField field="password" label="password" type="password" validate={required} />
              </Col>
              <Col>
                <TextField field="confirmPassword" label="confirmPassword" type="password" validate={required} />
              </Col>
            </Row>
          ) : null}

        <Row>
          <Col md={6}>
            <Select
              field="status"
              label="status"
              translateOptions
              options={[
                { id: 'pending', name: 'pending' },
                { id: 'active', name: 'active' },
                { id: 'disabled', name: 'disabled' },
              ]}
              validate={required}
            />
          </Col>
          <Col md={6}>
            <Select
              field="price"
              label="rate"
              idKey="value"
              labelKey="key"
              isLoading={ratesLoading}
              options={rates}
              validate={required}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Select
              field="categories"
              label="categories"
              multiple
              isLoading={categoriesLoading}
              options={categories}
              validate={required}
            />
          </Col>
        </Row>
      </Col>
    </Row>

    <TextField
      field="resume"
      type="textarea"
      label="resume"
      rows={5}
      maxLength={1000}
      validate={required}
    />
    <TextField
      field="experience"
      type="textarea"
      label="experience"
      maxLength={10000}
      rows={5}
    />
    <TextField
      field="welcomeMessageChat"
      label="welcomeMessageChat"
      type="textarea"
      rows={5}
      maxLength={1000}
    />
  </ModalForm>
) );
