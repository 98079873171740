import React, { useRef, useState, useCallback } from 'react';

const withModalEdit = ( Component ) => function Comp( props ) {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  return (
    <Component
      toEditId={toEditId}
      dataSubmitted={dataSubmitted}
      modalOpened={modalOpened}
      setModalOpened={setModalOpened}
      openEditModal={openEditModal}
      closeEditModal={closeEditModal}
      reloadData={reloadData}
      {...props}
    />
  );
};

export default withModalEdit;
