import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/img/brand/logo.png';

export default React.memo( ( {
  className, style = {}, width, href,
} ) => {
  const customWidth = typeof width === 'number' ? `${width}px` : width;
  const styles = { maxWidth: width ? customWidth : 'unset', ...style };
  const ImgLogo = <img alt="Mystic" src={logo} />;

  return (
    <div className={`logo${className ? ` ${className}` : ''}`} style={styles}>
      {href ? <Link to={href}>{ImgLogo}</Link> : ImgLogo}
    </div>
  );
} );
