import React from 'react';
import { Container } from 'reactstrap';

import {
  AsyncTable, AddBtn, SimpleHeader, ActiveIcon, TableEditBtn
} from 'components';

export default ( {
  data, isLoading, onOpenEditModal, onFetchData = () => {},
} ) => (
  <>
    <SimpleHeader title="categories" />
    <Container fluid>
      <AsyncTable
        data={data.data}
        total={data.total}
        isLoading={isLoading}
        rightContent={<AddBtn title="newCategory" onClick={onOpenEditModal} />}
        onFetchData={onFetchData}
        columns={[
          {
            Header: '',
            accessor: 'imageUri',
            customWidth: 80,
            disableSorting: true,
            Cell: ( { cell } ) => (
              cell.value ? <img src={cell.value} className="avatar border-radius-0" alt="" /> : null
            ),
          },
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value}
                onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
              />
            ),
          },
          {
            Header: 'active',
            accessor: 'active',
            Cell: ( { cell } ) => <ActiveIcon active={cell.value} />,
          },
          /* {
            accessor: 'id',
            disableSorting: true,
            customWidth: 100,
            Header: '',
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          }, */
        ]}
      />
    </Container>
  </>
);
