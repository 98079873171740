import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, Checkbox, Select,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  entityId, data, isLoading, open, onClose, onSubmit,
} ) => {
  const [isRegister, setIsRegister] = useState( false );


  return (
    <ModalForm
      initialValues={data}
      title={entityId ? 'editBonus' : 'newBonus'}
      showErrorsInFooter
      isLoading={isLoading}
      size="md"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Row>
        <Col md={12}>
          <TextField field="code" label="code" />
        </Col>
        <Col md={12}>
          <TextField field="fee" label="fee" type="number" validate={!isRegister ? required : null} />
        </Col>
        <Col md={12} className="mb-5">
          <Select
            field="useMode"
            label="useMode"
            translateOptions
            options={[
              { id: 'single_use', name: 'singleUse' },
              { id: 'single_use_customer', name: 'singleUsePerCustomer' },
            ]}
            validate={required}
          />
        </Col>
        <Col md={12} className="mb-5" />

      </Row>
    </ModalForm>
  );
} );
