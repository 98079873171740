import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';

import ExpressQuestionsView from 'views/ExpressQuestions';
import ChannelService from 'api/Channel';
import MessageService from 'api/Message';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';

const ExpressQuestions = ( { toggleErrorAlert, toggleLoading } ) => {
  const [channels, setChannels] = useState( { data: [], total: 0 } );
  const [channelsLoading, setChannelsLoading] = useState( true );
  const [messages, setMessages] = useState( [] );
  const [msgLoading, setMsgLoading] = useState( true );
  const channelsPagination = useRef( {
    page: 0,
    limit: 10,
  } );
  const msgPagination = useRef( {
    page: 0,
    limit: 10,
    hasMore: true,
  } );
  const channelSelected = useRef( null );

  const fetchMessages = useCallback( async () => {
    setMsgLoading( true );
    const [errors, response, responseData] = await handlePromise(
      MessageService.getMessages( channelSelected.current.id, {
        limit: msgPagination.current.limit,
        offset: msgPagination.current.page * msgPagination.current.limit,
      } ),
    );
    setMsgLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );

    msgPagination.current.hasMore = responseData.data.length >= msgPagination.current.limit;
    setMessages( msgPagination.current.page === 0
      ? responseData.data : [...messages, ...responseData.data] );
  }, [messages, toggleErrorAlert] );

  const selectChannel = useCallback( ( channel ) => {
    if ( !channelSelected.current || channelSelected.current.id !== channel.id ) {
      channelSelected.current = channel;
      msgPagination.current.page = 0;
      msgPagination.current.hasMore = true;
      fetchMessages();
    }
  }, [fetchMessages] );

  const fetchChannels = useCallback( () => {
    const getData = async () => {
      setChannelsLoading( true );
      const [errors, response, responseData] = await handlePromise(
        ChannelService.getChannels( {
          limit: channelsPagination.current.limit,
          offset: channelsPagination.current.page * channelsPagination.current.limit,
          'filters[status]': 'requested',
          'sortby[updatedAt]': 'DESC',
        } ),
      );
      setChannelsLoading( false );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setChannels( responseData );
      if ( !channelSelected.current && responseData.data[0] ) {
        selectChannel( responseData.data[0] );
      }
    };
    getData();
  }, [selectChannel, toggleErrorAlert] );

  const loadNextPageChannels = useCallback( () => {
    channelsPagination.current.page += 1;
    fetchChannels();
  }, [fetchChannels] );

  const loadPrevPageChannels = useCallback( () => {
    channelsPagination.current.page -= 1;
    fetchChannels();
  }, [fetchChannels] );

  const resetChannels = useCallback( () => {
    channelsPagination.current.page = 0;
    fetchChannels();
  }, [fetchChannels] );

  useEffect( () => {
    fetchChannels();
  }, [] ); //eslint-disable-line

  const loadNextPageMessages = useCallback( () => {
    msgPagination.current.page += 1;
    fetchMessages();
  }, [fetchMessages] );

  const sendTextMessage = useCallback( async ( message ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      MessageService.sendMessages( channelSelected.current.id, {
        body: message,
      } ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    channelSelected.current = null;
    fetchChannels();
  }, [fetchChannels, toggleErrorAlert, toggleLoading] );

  return (
    <ExpressQuestionsView
      channels={channels}
      channelsLoading={channelsLoading}
      channelsPagination={channelsPagination.current}
      channelSelected={channelSelected.current}
      loadNextPageChannels={loadNextPageChannels}
      loadPrevPageChannels={loadPrevPageChannels}
      resetChannels={resetChannels}
      selectChannel={selectChannel}
      messages={messages}
      msgLoading={msgLoading}
      hasMoreMessages={msgPagination.current.hasMore}
      loadNextPageMessages={loadNextPageMessages}
      sendTextMessage={sendTextMessage}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ExpressQuestions );
