import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import * as store from './store';
import * as serviceWorker from './serviceWorker';

const storeMain = store.default();
const render = () => {
  ReactDOM.render(
    <Provider store={storeMain.store}>
      <ConnectedRouter history={store.history}>
        <PersistGate loading={null} persistor={storeMain.persistor}>
          <Switch>
            <Route path="/" component={App} />
            <Redirect from="*" to="/" />
          </Switch>
        </PersistGate>
      </ConnectedRouter>
    </Provider>,
    document.getElementById( 'root' ),
  );
};

render();

serviceWorker.register();
