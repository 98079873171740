import React from 'react';
import { connect } from 'react-redux';

import BonusEditView from 'views/Bonus/Edit';
import { useFetch, useSubmit } from 'hooks';
import BonusService from 'api/Bonus';
import SettingsActions from 'store/reducers/Settings';

const BonusEdit = ( {
  entityId, modalOpened, onReload, onCloseModal, toggleLoading, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => BonusService.getBonus( entityId ),
    toggleErrorAlert,
    conditional: !!entityId && modalOpened,
    deps: [entityId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => {
      const dataToSend2 = dataToSend;
      dataToSend2.fee = dataToSend2.inRegister === '1' ? 0 : dataToSend2.fee;
      return ( entityId
        ? BonusService.updateBonus( entityId, dataToSend2 )
        : BonusService.saveBonus( dataToSend2 ) );
    },
    toggleLoading,
    format: ( dataToFormat ) => ( {
      code: dataToFormat.code,
      fee: dataToFormat.fee,
      inRegister: dataToFormat.inRegister ? '1' : '0',
      useMode: dataToFormat.useMode,
    } ),
    deps: [entityId],
    callback: onReload,
  } );

  return (
    <BonusEditView
      entityId={entityId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
} );

export default connect( null, mapDispatchToProps )( BonusEdit );
