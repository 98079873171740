import React from 'react';
import classnames from 'classnames';

import Select from '../../Select';
import AsyncSelect from '../../AsyncSelect';

const TableSelectFilter = React.memo( ( {
  name, filters, setFilter, inline, ...rest
} ) => {
  const SelectComponent = rest.loadOptions ? AsyncSelect : Select;
  return (
    <SelectComponent
      {...rest}
      size="xsm"
      name={name}
      formGroupClass={classnames( 'm-0', {
        'd-flex align-items-center': inline,
      } )}
      labelClass={classnames( {
        'mr-2': inline,
      } )}
      value={filters[name]}
      onChange={( value ) => setFilter( name, value )}
    />
  );
} );

export default TableSelectFilter;
