import api from 'config/Api';

const baseEndpointList = 'administration/credits';
const baseEndpoint = 'administration/credit';

export default {

  getCredits( params ) {
    return api.get( baseEndpointList, params );
  },

  getCredit( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveCredit( data ) {
    return api.post( baseEndpoint, data );
  },

  updateCredit( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteCredit( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
