import React from 'react';
import { Form } from 'react-final-form';
import _ from 'lodash';

import { Intl } from 'components';

export default React.memo( ( {
  children, showErrorsInFooter, showErrorsInHeader,
  showSuccessMsgInFooter, body, footer, successMsg, ...rest
} ) => (
  <Form
    {...rest}
    render={( {
      handleSubmit, submitErrors, submitSucceeded, form,
    } ) => (
      <form onSubmit={handleSubmit} noValidate>
        {showErrorsInHeader && _.isString( submitErrors )
        && <div className="error my-3 text-center"><Intl id={submitErrors} /></div>}

        {body && _.isFunction( body ) ? body( form ) : body}
        {!body && children[0]}

        {showSuccessMsgInFooter && submitSucceeded
        && <div className="success my-3 text-center"><Intl id={successMsg} /></div>}

        {showErrorsInFooter && _.isString( submitErrors )
        && <div className="error my-3 text-center"><Intl id={submitErrors} /></div>}

        {footer || ( ( children && children[1] ) ? children[1] : null )}
      </form>
    )}
  />
) );
