import React from 'react';
import {
  Button,
  Row,
  Col,
} from 'reactstrap';

import {
  Form, TextField, Intl, Auth,
} from 'components';
import { composeValidators, required, email } from 'config/InputErrors';

export default React.memo( ( { onSubmit } ) => (
  <Auth
    msg={<Intl id="loginTitle" />}
  >
    <Form
      onSubmit={onSubmit}
      showErrorsInFooter
      body={() => (
        <>
          <Row>
            <Col md={10} className="m-auto">
              <TextField
                field="email"
                validate={composeValidators( required, email )}
                placeholder="email"
                size="md"
              />
              <TextField
                field="password"
                type="password"
                validate={required}
                placeholder="password"
                size="md"
                classNames={{
                  formGroup: 'm-0',
                }}
              />
            </Col>
          </Row>
        </>
      )}
      footer={(
        <Row>
          <Col md={10} className="mt-3 mx-auto">
            <Button color="primary" className="text-md" block>
              <Intl id="login" />
            </Button>
          </Col>
        </Row>
      )}
    />
  </Auth>
) );
