import React, { useCallback, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { ConfirmationModal, Intl } from 'components';

export default React.memo( ( { actions } ) => {
  const [deleteModal, setDeleteModal] = useState( false );
  const deleteCb = useRef( null );
  const toggleDeleteModal = useCallback( ( cb ) => {
    deleteCb.current = cb;
    setDeleteModal( ( state ) => !state );
  }, [] );

  return (
    <div className="d-flex">
      {actions ? _.map( actions, ( {
        link, label, onClick, isEdit, isDelete, ...rest
      }, index ) => (

        <div key={index}>
          {link
            ? (
              <Link to={link} className="btn btn-outline-primary btn-app-sm">
                <Intl id={label} />
              </Link>
            ) : (
              <Button
                color="link"
                outline
                size="app-sm"
                onClick={isDelete ? () => toggleDeleteModal( onClick ) : onClick}
                {...rest}
              >
                <Intl id={isDelete ? label || 'delete' : label} />
              </Button>
            )}
        </div>
      ) ) : null}

      <ConfirmationModal
        open={deleteModal}
        onConfirm={() => deleteCb.current( toggleDeleteModal )}
        onClose={toggleDeleteModal}
      />
    </div>
  );
} );
