import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import DetailView from 'views/Consultant/Detail';
import { useFetch, useFetchTableData } from 'hooks';
import ConsultantService from 'api/Consultant';
import SettingsActions from 'store/reducers/Settings';
import Payment from '../Payment';

const Detail = ( {
  match, toggleErrorAlert,
} ) => {
  const entityId = useRef( match.params.id );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ConsultantService.getConsultant( entityId.current ),
    toggleErrorAlert,
    conditional: !!entityId,
    deps: [dataSubmitted],
  } );

  const [fetchOperations, operations, operationsLoading] = useFetchTableData( {
    promise: ( params ) => ConsultantService.getConsultantOperations( entityId.current, params ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const closePaymentModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const openPaymentModal = useCallback( () => {
    setModalOpened( true );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closePaymentModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closePaymentModal] );
  return (
    <>
      <DetailView
        data={data}
        createOperation={openPaymentModal}
        isLoading={dataLoading}
        operations={operations}
        operationsLoading={operationsLoading}
        onFetchOperations={fetchOperations}
      />

      <Payment
        entityId={entityId.current}
        modalOpened={modalOpened}
        onCloseModal={closePaymentModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( Detail );
