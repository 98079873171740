import React, { useCallback } from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import debounce from 'debounce-promise';

import { Intl } from 'components';
import { handlePromise } from 'utils';
import {
  customStyles, IconOption, IconSingleValue, Clear,
} from '../SelectCustomComponents';

const AsyncSelectInput = React.memo( ( {
  label, labelKey = 'name', idKey = 'id', isInvalid, inputName, multiple, placeholder,
  classNames = {}, size = 'sm', value, translateOptions, intl, meta, translateValues, onChange, inputOnChange,
  loadOptions, filterFn, ...rest
} ) => {
  const load = useCallback( async ( query ) => {
    const [errors, response, responseData] = await handlePromise(
      loadOptions( query ),
    );

    return new Promise( ( resolve, reject ) => {
      if ( !response.ok ) return reject( errors );
      const options = filterFn ? filterFn( responseData.data ) : responseData.data;

      resolve( _.map( options, ( option ) => ( {
        value: option[idKey],
        // eslint-disable-next-line no-nested-ternary
        label: _.isFunction( labelKey ) ? labelKey( option ) : translateOptions ? option[labelKey]
          ? intl.formatMessage( { id: option[labelKey] } )
          : option[labelKey]
          : option[labelKey],
        icon: option.icon,
        original: option,
      } ) ) );
    } );
  }, [idKey, labelKey, loadOptions, translateOptions, intl, filterFn] );

  const debouncedLoadOptions = debounce( load, 250 );

  return (
    <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
      {label
      && (
        <label className={classnames( classNames.label, 'form-control-label' )}>
          <Intl id={label} />
        </label>
      )}

      <AsyncSelect
        {...rest}
        loadOptions={debouncedLoadOptions}
        isMulti={multiple}
        placeholder={placeholder ? intl.formatMessage( { id: placeholder } ) : null}
        styles={customStyles}
        classNamePrefix="custom-select"
        className={classnames( 'custom-select-container form-control', {
          'is-invalid': isInvalid,
          'form-control-lg': size === 'lg',
          'form-control-sm': size === 'sm',
          'form-control-xsm': size === 'xsm',
        } )}
        onChange={( val ) => {
          if ( multiple || !val || val.value !== value ) {
            if ( inputOnChange ) inputOnChange( val );
            if ( onChange ) onChange( val );
          }
        }}
        value={value}
        components={{
          Option: IconOption,
          SingleValue: IconSingleValue,
          ClearIndicator: Clear,
        }}
      />

      {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
    </FormGroup>
  );
} );


export default injectIntl( AsyncSelectInput );
