import React from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

export default ( {
  dataLength, total, pagination, loadNextPage, loadPrevPage,
} ) => (
  <nav aria-label="...">
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem className={pagination.page === 0 ? 'disabled' : ''}>
        <PaginationLink onClick={loadPrevPage}>
          <i className="fas fa-angle-left" />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem className="active">
        <PaginationLink>
          {pagination.page + 1}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem className={(
        dataLength < pagination.limit
        || total <= ( pagination.page + 1 ) * pagination.limit
      ) ? 'disabled' : ''}
      >
        <PaginationLink onClick={loadNextPage}>
          <i className="fas fa-angle-right" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  </nav>
);
