import React from 'react';
import { NavLink as NavLinkRRD, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  NavbarBrand,
  Badge,
} from 'reactstrap';

import { Logo, Intl } from 'components';

class Sidebar extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      collapseOpen: false,
      expressCount: props.expressCount,
      pendingPayCount: props.pendingPayCount,
      ...this.getCollapseStates( props.routes ),
    };
  }

  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if ( !document.body.classList.contains( 'g-sidenav-pinned' ) ) {
      document.body.classList.add( 'g-sidenav-show' );
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if ( !document.body.classList.contains( 'g-sidenav-pinned' ) ) {
      document.body.classList.remove( 'g-sidenav-show' );
    }
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = ( routes ) => {
    let initialState = {};
    routes.map( ( prop ) => {
      if ( prop.views ) {
        initialState = {
          [prop.state]: this.getCollapseInitialState( prop.views ),
          ...this.getCollapseStates( prop.views ),
          ...initialState,
        };
      }
      return null;
    } );
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState( routes ) {
    // eslint-disable-next-line no-plusplus
    for ( let i = 0; i < routes.length; i++ ) {
      if ( routes[i].views && this.getCollapseInitialState( routes[i].views ) ) {
        return true;
      } if ( window.location.href.indexOf( routes[i].path ) !== -1 ) {
        return true;
      }
    }
    return false;
  }

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    const { toggleSidenav } = this.props;
    if ( window.innerWidth < 992 ) {
      toggleSidenav();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = ( routes ) => routes.map( ( prop ) => {
    const { location } = this.props;
    const { state } = this;
    if ( prop.redirect ) {
      return null;
    }
    if ( prop.views ) {
      const newState = {};

      newState[prop.state] = !state[prop.state];
      return (
        <NavItem key={prop.name}>
          <NavLink
            data-toggle="collapse"
            aria-expanded={state[prop.state]}
            className={classnames( 'cursor-pointer', {
              active: this.getCollapseInitialState( prop.views ),
            } )}
            onClick={() => this.setState( newState )}
          >
            {prop.icon ? <i className={prop.icon} /> : null}
            <span className="nav-link-text"><Intl id={prop.name} /></span>
          </NavLink>
          <Collapse isOpen={state[prop.state]}>
            <Nav className="nav-sm flex-column">
              {this.createLinks( prop.views )}
            </Nav>
          </Collapse>
        </NavItem>
      );
    }
    return (
      <NavItem
        className={classnames( {
          active: prop.path === '/' ? location.pathname === '/' : location.pathname.indexOf( prop.path ) > -1,
        } )}
        key={prop.path}
      >
        <NavLink
          to={prop.path}
          activeClassName=""
          onClick={this.closeSidenav}
          tag={NavLinkRRD}
        >
          {prop.icon !== undefined ? (
            <>
              <i className={prop.icon} />
              <span className="nav-link-text">
                <Intl id={prop.name} />
                {prop.name.indexOf( 'express' ) !== -1
                  && <Badge color="danger" pill className="ml-1">{this.props.expressCount}</Badge>}
                {prop.name.indexOf( 'payment' ) !== -1
                  && <Badge color="danger" pill className="ml-1">{this.props.pendingPayCount}</Badge>}
              </span>
            </>
          ) : (
            <Intl id={prop.name} />
          )}
        </NavLink>
      </NavItem>
    );
  } );

  render() {
    const { routes, toggleSidenav, sidebarCollapsed } = this.props;

    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          <NavbarBrand tag="div">
            <Logo
              href="/accounts"
              className="navbar-brand-img sidebar-logo"
              width="100"
            />
          </NavbarBrand>
          <div className="ml-auto">
            {/* eslint-disable-next-line */}
            <i
              className={classnames( 'sidenav-toggler d-none d-lg-block', {
                active: !sidebarCollapsed,
              } )}
              onClick={toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </i>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen>
            <Nav navbar>{this.createLinks( routes )}</Nav>
          </Collapse>
        </div>
      </div>
    );

    return (
      <Navbar
        className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf( 'Win' ) > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  logo: null,
  toggleSidenav: () => {},
  sidebarCollapsed: false,
  expressCount: 0,
  pendingPayCount: 0,
};

export default withRouter( Sidebar );
