import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import LoginView from 'views/Login';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise, storage } from 'utils';
import AuthService from 'api/Auth';

const Login = ( { toggleLoading, loginUser, history } ) => {
  const submitForm = useCallback( async ( formData ) => {
    toggleLoading( true );

    const [errors, response, data] = await handlePromise( AuthService.loginUser( {
      username: formData.email,
      password: formData.password,
    } ) );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors || 'apiError';
    }

    storage.set( 'mystic:token', data.access_token );
    loginUser( data );
    toggleLoading( false );
    return history.push( '/' );
  }, [history, loginUser, toggleLoading] );

  return ( <LoginView onSubmit={submitForm} /> );
};

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  toggleLoading: SettingsActions.toggleLoading,
  switchLanguage: SettingsActions.switchLanguage,
} );

export default connect( null, mapDispatchToProps )( Login );
