import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  ListGroup,
  Row,
  Col,
  Badge,
} from 'reactstrap';
import _ from 'lodash';

import {
  ExpressQuestionsChannel, ExpressQuestionsChannelPagination,
  ExpressQuestionsSendMessage, ExpressQuestionsMessagesList, Intl,
} from 'components';

export default ( {
  channels, channelsPagination, loadNextPageChannels, loadPrevPageChannels, resetChannels,
  channelSelected, selectChannel, messages, hasMoreMessages, loadNextPageMessages, sendTextMessage,
} ) => (
  <Container fluid className="mt-4 express-questions">
    <Row>
      <Col md="3">
        <Card className="border-radius-0">
          <CardHeader className="px-3 d-flex justify-content-between">
            <h4 className="mb-0 font-weight-500 d-flex align-items-center">
              <Intl id="expressQuestions" />
              <Badge color="danger" pill className="ml-1">{channels.total}</Badge>
            </h4>
            <i className="fas fa-sync-alt text-primary cursor-pointer px-2" onClick={resetChannels} />
          </CardHeader>

          <CardBody className="p-0 express-questions-msg-body">
            <ListGroup flush>
              {_.map( channels.data, ( item ) => (
                <ExpressQuestionsChannel
                  key={item.id}
                  data={item}
                  selectChannel={selectChannel}
                  channelSelectedId={channelSelected ? channelSelected.id : null}
                />
              ) )}
            </ListGroup>

            {( !channels.data || !channels.data.length )
            && (
              <div className="text-center mt-5">
                <i className="fa fa-inbox fa-3x text-gray-3" />
                <h4 className="font-weight-500 uppercase text-gray-3">No channels</h4>
              </div>
            )}
          </CardBody>

          <CardFooter className="py-3">
            <ExpressQuestionsChannelPagination
              dataLength={channels.data.length}
              total={channels.total}
              pagination={channelsPagination}
              loadNextPage={loadNextPageChannels}
              loadPrevPage={loadPrevPageChannels}
            />
          </CardFooter>
        </Card>
      </Col>

      <Col>
        {channelSelected
          ? (
            <Card className="border-radius-0 chat-messages">
              <CardHeader className="py-2">
                <Row className="align-items-center">
                  <Col>
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        {channelSelected.customer.imageUri
                          ? (
                            <img
                              src={channelSelected.customer.imageUri}
                              className="avatar rounded-circle"
                              alt="..."
                            />
                          )
                          : <div className="avatar avatar rounded-circle"><i className="fa fa-user text-dark" /></div>}
                      </Col>
                      <Col className="pl-md-0">
                        <h4 className="mb-0 font-weight-500">
                          {channelSelected.customer.name}
                        </h4>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="text-right">
                    <small className="text-gray">
                      <Intl id="expressQuestionsHelp" />
                    </small>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="py-2 position-relative">
                <ExpressQuestionsMessagesList
                  messages={messages}
                  hasMoreMessages={hasMoreMessages}
                  loadNextPageMessages={loadNextPageMessages}
                />
              </CardBody>

              <CardFooter>
                <ExpressQuestionsSendMessage sendTextMessage={sendTextMessage} />
              </CardFooter>
            </Card>
          )
          : (
            <Card className="border-radius-0">
              <CardBody className="text-center py-5">
                <i className="fa fa-inbox fa-3x text-gray-3" />
                <h4 className="font-weight-500 uppercase text-gray-3">No messages</h4>
              </CardBody>
            </Card>
          )}
      </Col>
    </Row>
  </Container>
);
