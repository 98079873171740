import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Intl } from 'components';
import { Button } from 'reactstrap';

export default ( {
  open, onConfirm, onClose, title, confirmBtnText, subtitle, translateValues, translateTitle = true,
  confirmClose,
} ) => (
  <SweetAlert
    show={open}
    customClass="sweetalert-confirmation"
    title={(
      <div className="d-flex flex-column justify-content-between">
        <i className="fa fa-times cursor-pointer text-right close text-sm" onClick={confirmClose ? onConfirm : onClose}/>
        <div className="text-body text-md font-weight-400">
          <span className="d-block">
            {/* eslint-disable-next-line no-nested-ternary */}
            {title ? translateTitle ? <Intl id={title} /> : title
              : <Intl id="deleteModal.confirmation" values={translateValues} />}
          </span>
          <span className="d-block">
            {!!subtitle && <Intl id={subtitle} />}
          </span>
        </div>
      </div>
    )}
    customButtons={(
      <>
        <Button
          color="danger"
          outline
          size="app-sm"
          type="button"
          onClick={onConfirm}
        >
          {confirmBtnText
            ? <Intl id={confirmBtnText} /> : <Intl id="yes" />}
        </Button>
        {!!onClose
        && (
          <Button
            color="primary"
            outline
            size="app-sm"
            type="button"
            onClick={onClose}
          >
            <Intl id="cancelBtn" />
          </Button>
        )}
      </>
    )}
    onConfirm={onConfirm}
    onCancel={onClose}
  />
);
