import React from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import _ from 'lodash';
import { Intl } from 'components';

const RadioButtonInput = React.memo( ( {
  label, value, inputName, options, inline, translateValues, translateOptions = true,
  meta, isInvalid, showErrors = true, classNames = {}, inputOnChange, onChange,
} ) => (
  <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
    {label
    && (
      <label className={classnames( classNames.label, 'form-control-label' )}>
        <Intl id={label} />
      </label>
    )}

    <div>
      {_.map( options, ( option ) => (
        <div
          className={classnames( 'custom-control custom-radio', classNames.radio, {
            'd-inline-flex mr-5': inline,
          } )}
          key={option.id}
        >
          <input
            className="custom-control-input"
            name={inputName}
            checked={option.id === value}
            id={option.id}
            onChange={() => {
              if ( inputOnChange ) inputOnChange( option.id );
              if ( onChange ) onChange( option.id );
            }}
            type="radio"
          />
          <label
            className="custom-control-label"
            htmlFor={option.id}
          >
            {translateOptions ? <Intl id={option.name} /> : option.name}
          </label>
        </div>
      ) )}
    </div>

    {isInvalid && showErrors
    && (
      <div className="invalid-feedback">
        <Intl id={meta.error || meta.submitError} values={translateValues} />
      </div>
    )}
  </FormGroup>
) );

export default RadioButtonInput;
