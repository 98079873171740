import React from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import { Intl } from 'components';

const CheckboxInput = React.memo( ( {
  label, value, inputName, inputOnChange, onChange, classNames = {},
  translateValues, meta, isInvalid, formId = '', translateLabel = true,
} ) => (
  <FormGroup className={classnames( classNames.formGroup, { 'is-invalid': isInvalid } )}>
    <div className="custom-control custom-checkbox custom-checkbox-white">
      <input
        className="custom-control-input"
        checked={value}
        id={`${formId}-${inputName}`}
        type="checkbox"
        onChange={( e ) => {
          if ( inputOnChange ) inputOnChange( e.target.checked );
          if ( onChange ) onChange( e.target.checked );
        }}
      />

      <label
        className={classnames( 'custom-control-label', classNames.label )}
        htmlFor={`${formId}-${inputName}`}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {label ? ( translateLabel ? <Intl id={label} /> : label ) : null}
      </label>

    </div>

    {isInvalid
    && (
      <div className="invalid-feedback">
        <Intl id={meta.error || meta.submitError} values={translateValues} />
      </div>
    )}
  </FormGroup>
) );

export default CheckboxInput;
