import React from 'react';
import { Row, Col, Container } from 'reactstrap';

export default () => (
  <Container fluid>
    <Row>
      <Col>
        <div className="copyright text-center">
          <span className="font-weight-bold">Mistic</span>
          {' '}
          ©
          {' '}
          {new Date().getFullYear()}
        </div>
      </Col>
    </Row>
  </Container>
);
