import api from 'config/Api';

const baseEndpoint = 'operation/payment-request';

export default {

  getPaymentRequests( params ) {
    return api.get( baseEndpoint, params );
  },

  completePaymentRequest( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
};
