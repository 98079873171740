import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'lodash';

import ConsultantEditView from 'views/Consultant/Edit';
import { useFetch } from 'hooks';
import ConsultantService from 'api/Consultant';
import CategoryService from 'api/Category';
import SettingsService from 'api/Settings';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise, formatPhone } from 'utils';

const ConsultantEdit = ( {
  entityId, modalOpened, onReload, onCloseModal, toggleLoading, toggleErrorAlert,
} ) => {
  const [categories, categoriesLoading] = useFetch( {
    initialState: [],
    promise: () => CategoryService.getCategories(),
    format: ( dataToFormat ) => ( dataToFormat && dataToFormat.data ? dataToFormat.data : [] ),
    toggleErrorAlert,
    conditional: modalOpened,
    deps: [modalOpened],
  } );

  const [rates, ratesLoading] = useFetch( {
    initialState: [],
    promise: () => SettingsService.getSetting(),
    format: ( dataToFormat ) => ( dataToFormat && dataToFormat.rates
      ? dataToFormat.rates.map( ( item ) => ( { ...item, value: Number( item.value ) } ) ) : [] ),
    toggleErrorAlert,
    conditional: modalOpened,
    deps: [modalOpened],
  } );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ConsultantService.getConsultant( entityId ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      imageFile: dataToFormat.imageUri,
      categories: _.map( dataToFormat.categories, 'id' ),
      phoneNumber: formatPhone( dataToFormat.codeCountry
        ? dataToFormat.codeCountry + dataToFormat.phoneNumber : dataToFormat.phoneNumber ),
    } ),
    toggleErrorAlert,
    conditional: !!entityId && modalOpened,
    deps: [entityId, modalOpened],
    reInit: true,
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const formattedPhone = parsePhoneNumberFromString( formData.phoneNumber );
    let dataToSend = formatDataToSend( formData, form );
    dataToSend = {
      ...dataToSend,
      phoneNumber: formattedPhone.nationalNumber,
      codeCountry: formattedPhone.countryCallingCode,
      confirmPassword: undefined,
      imageFile: undefined,
    };
    const [errors, response, responseData] = await handlePromise( entityId
      ? ConsultantService.updateConsultant( entityId, dataToSend )
      : ConsultantService.saveConsultant( dataToSend ) );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( formData.imageFile && formData.imageFile.file ) {
      const [imageErrors, imageResponse] = await handlePromise(
        ConsultantService.updateConsultantImage( responseData.id, {
          imageFile: formData.imageFile.file,
        } ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }
    toggleLoading( false );
    onReload();
  }, [entityId, toggleLoading, onReload] );

  return (
    <ConsultantEditView
      rates={rates}
      ratesLoading={ratesLoading}
      categories={categories}
      categoriesLoading={categoriesLoading}
      entityId={entityId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
} );

export default connect( null, mapDispatchToProps )( ConsultantEdit );
